/* ********************************
***********************************
           Search CSS
***********************************
********************************* */
.jVAcwv * {
    box-sizing: border-box !important;
  }
  .jaPzIU {
    height: 100%;
  }
  .hCnDID {
    width: 100%;
    height: 100%;
  }
  .cxiIfM {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .edsbtf {
    position: absolute;
    z-index: 0;
  }
  
  .fvVanw {
    display: flex;
    -webkit-box-pack: end !important;
    justify-content: flex-end;
  }
  
  .fDkWMd {
    position: relative;
  }
  .gCSJQb {
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 48em)
  {
  .gOjHMg {
      min-height: 330px;
  }
  }
  .eTNbOc {
    width: 340px;
  }
  @media screen and (min-width: 40em){
    .eLJNWg {
    padding-left: 32px;
    padding-right: 32px;
  }
  .eTNbOc {
    width: 458px;
  }
  }
  .eLJNWg {
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1280px;
  }
  @media screen and (min-width: 64em){
    .gCSJQb {
      width: 50%;
    }
  }
  .dAZNoa {
      overflow-y: clip;
  }
  .jhsxhN {
    z-index: 3;
  }
  @media screen and (min-width: 48em){
  .jhsxhN {
      z-index: 1;
  }
  }
  
  .fNLzQu {
      position: absolute;
      bottom: 0px;
      right: -30%;
  }
  @media screen and (min-width: 32em){
  .fNLzQu {
      right: -20%;
  }
  }
  
  .ibItNb {
    z-index: 2;
  }
  
  .cngUVh {
    position: absolute;
    top: -70%;
    left: -45%;
  }
  @media screen and (min-width: 64em){
    /* .fNLzQu {
      right: -45%;
  } */
  .fNLzQu {
      right: -70%;
  }
  .dAZNoa {
    overflow-y: unset;
  }
  .eTNbOc {
      width: 576px;
  }
  .gOjHMg {
      min-height: 576px;
  }
  
    .cngUVh {
      top: -60%;
      left: -15%;
    }
  
  }
  .gubUFG, .gubUFG > svg {
    width: 100%;
    height: 100%;
  }
  .krWSDp {
    display: inline-block;
  }
  .ioLaXq, .ioLaXq > svg {
    width: 100%;
    height: 100%;
  }
  .bFaeAz {
    display: inline-block;
  }
  .jhFlZt {
    cursor: pointer;
    text-decoration: none;
    color: rgb(0, 104, 239);
  }
  .jhFlZt:hover {
      color: rgb(0, 68, 153) !important;
      text-decoration: underline !important;
  }
  .kcHLYw {
    overflow: hidden;
  }
  .ixHBMT {
    position: absolute;
    top: 8%;
    right: -20%;
  }
  .cbSskZ {
    overflow: hidden;
  }
  .jAekbq {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .bwaFpX {
    width: 100%;
    height: 100%;
    border-radius: 9999px 0px 0px 9999px;
  }
  .jpIeBg {
    z-index: 5;
  }
  .bSISgq {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(236, 239, 242);
    background-attachment: scroll;
    background-image: url(../Images/home1.jpeg);
    width: 100%;
    height: 100%;
  }
  .ZLSPb {
    padding-top: 16px;
  }
  .jhIZHl {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 24, 51);
    border-radius: 24px;
    margin-left: 32px;
    margin-right: 32px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
  }
  .cNNZPq {
    position: relative;
  }
  .hIDAJO {
    display: flex;
  }
  .kjHRhB {
    margin-left: 16px;
    margin-right: 16px;
  }
  .gFVzYR {
    z-index: 1;
    position: relative;
  }
  .bNDgsd {
    z-index: 1;
    position: relative;
  }
  .dUQmGt {
    padding: 16px;
  }
  .ktsBCw > div {
    left: 0px;
  }
  .gDaPee {
    border-width: 0px;
    border-color: rgb(192, 202, 213);
    border-radius: 2px;
    border-style: solid;
  }
  .bnGnEl {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .gBtaQQ {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 32em)
  {
  .gBtaQQ {
      flex-wrap: wrap;
  }
  }
  
  .dQFMGm {
      width: 100%;
      margin-bottom: 0px;
      padding-left: 8px;
      padding-right: 8px;
  }
  .dsJkWl {
    width: 100%;
  }
  .iTwuwh {
    text-align: left;
  }
  .gSCCYN {
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .hILmdS {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 24, 51);
    padding: 0px;
    margin-top: 0px;
    border-radius: 24px;
  }
  .evpJHe {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
  .SjeQr {
    width: 100%;
    padding-bottom: 16px;
  }
  .kPRUfN {
    border-radius: 12px;
  }
  .jcqFHr {
    display: block;
    margin: 0px 0px -20px 40px;
    color: rgb(79, 111, 143);
    padding-bottom: 0px;
    width: auto;
    font-size: 10px;
    padding-top: 6px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.2px;
  }
  .fQyDwd {
    margin-right: -40px;
  }
  .kHgnEk {
    outline: none;
  }
  .aLkqs {
    flex: 0 0 auto;
    line-height: 1;
    color: rgb(0, 104, 239);
    margin-left: 8px;
    margin-right: 8px;
    width: 24px;
  }
  
  .hmtJkv {
      position: relative;
      appearance: none;
      display: block;
      width: 100%;
      font-family: inherit;
      color: rgb(0, 24, 51);
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
      margin: 0px;
      padding: 14px 12px 14px 40px;
      border-color: rgb(192, 202, 213);
      border-radius: 12px;
      font-size: 16px;
  }
  .dtZXam {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    text-align: left;
  }
  @media screen and (min-width: 40em)
  {
    .dtZXam {
      width: 50%;
  }
  /* .cngUVh {
    top: -75%;
    left: -28%;
  } */
    .hILmdS {
      border-radius: 12px;
  }
  .hmtJkv {
    font-size: 14px;
  }
  .SjeQr {
    padding-bottom: 0px;
  }
  .dQFMGm {
      width: 100%;
      margin-bottom: 16px;
  }
  }
  
  .dETXCd {
      margin-left: -8px;
      margin-right: -8px;
  }
  .cENklz {
    width: 412px;
  }
  .jixyDq {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-family: inherit;
    cursor: pointer;
    border-width: 0px;
    border-style: solid;
    border-radius: 9999px;
    font-size: 14px;
    -webkit-font-smoothing: inherit;
    font-weight: 500;
    color: rgb(0, 104, 239);
    line-height: 1.4;
    vertical-align: inherit;
    background-color: transparent;
    margin-right: 0px;
    width: 100%;
    padding: 0px;
  }
  @media screen and (min-width: 48em)
  {
  .jixyDq {
    margin-right: 16px;
    width: auto;
    padding-right: 16px;
  }
  .cENklz {
    width: 640px;
  }
  /* .cngUVh {
    top: -100%;
    left: -35%;
  } */
  }
  .iTlgTb {
    background: rgb(237, 240, 243);
  }
  .eoQCaH {
    padding-top: 2px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .ctYaUb {
    display: inline-block;
  }
  .jUbjrh {
    font-size: 24px;
    line-height: 24px;
  }
  .kDyrQm {
    display: inline-block;
  }
  .bmTETO {
    width: 97%;
    height: 212px;
  }
  .cAIQgh {
    display: block;
    margin: 0px 0px -20px 40px;
    color: rgb(79, 111, 143);
    width: auto;
    font-size: 10px;
    padding-top: 6px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.2px;
  }
  .kpaNAY {
    position: relative;
    appearance: none;
    display: block;
    width: 100%;
    font-family: inherit;
    color: rgb(0, 24, 51);
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    padding: 14px 12px 14px 40px;
    border-color: rgb(192, 202, 213);
    margin: 0px;
    border-radius: 12px;
    font-size: 16px;
  }
  @media screen and (min-width: 48em){
    .bmTETO {
      height: 240px;
    }
  }
  .eghPaz {
    width: 100%;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .brdBgp {
    padding: 0px;
    top: 0px;
  }
  .gindoq {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 24, 51);
    border-radius: 24px;
    width: 100%;
  }
  @media screen and (min-width: 64em)
  {
  .bmTETO {
    height: 420px;
  }
  .dtZXam {
    width: 50%;
  }
  .eghPaz {
    width: 50%;
  }
  .cENklz {
    width: 115%;
  }
  }
  .eCdPAh {
    display: flex;
    flex-wrap: wrap;
  }
  
  .hYVpLi {
      margin-top: -8px;
      margin-bottom: -8px;
  }
  .pXlTP:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 24, 51);
  }
  .euugUZ > .sc-eqUAAy {
    justify-content: left;
  }
  .euugUZ {
    position: relative;
    height: 56px;
    padding: 0px;
  }
  .pXlTP {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.5;
    cursor: pointer;
    border-width: 0px;
    border-style: solid;
    border-radius: 12px;
    font-size: 14px;
    padding: 9.5px 18px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 24, 51);
    width: 100%;
  }
  .jIfocp {
    outline: none;
  }
  .ctauFf {
    font-size: 14px;
    font-weight: 500;
  }
  .ioEqsK:read-only {
    position: absolute !important;
    background-color: transparent !important;
    color: rgb(0, 24, 51) !important;
    cursor: pointer !important;
  }
  .fKFmLo {
    flex: 0 0 auto;
    line-height: 1;
    color: rgb(0, 104, 239);
    /* margin-left: 10px; */
    margin-right: 10px;
    width: 24px;
  }
  .pXlTP:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 24, 51);
  }
  .jzjDHJ {
    position: relative;
    appearance: none;
    display: block;
    width: 100%;
    font-family: inherit;
    color: rgb(0, 24, 51);
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    margin: 0px;
    padding: 14px 12px;
    border-color: rgb(192, 202, 213);
    border-radius: 12px;
    font-size: 16px;
  }
  .ioEqsK {
    position: absolute;
    left: 0px;
    height: 56px;
  }
  .jKftlo {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 32px;
    height: 56px;
  }
  .kRohZa {
    background-color: rgb(208, 241, 172);
    color: rgb(0, 24, 51);
    padding: 4px;
    border-radius: 12px;
    height: 100%;
  }
  .bkdMKt {
    flex: 0 0 auto;
    line-height: 1;
    color: rgb(1, 112, 0);
    margin: 4px 8px 4px 4px;
    width: 24px;
  }
  .stlTT {
    color: rgb(1, 112, 0);
    font-weight: 700;
    padding-right: 4px;
    font-size: 12px;
    margin: auto auto auto 0px;
  }
  .fkoOZw {
    outline: none;
  }
  @media screen and (min-width: 40em)
  {
    .kpaNAY {
      font-size: 14px;
  }
  .jKftlo {
    width: 50%;
  }
  .jzjDHJ {
    font-size: 14px;
  }
  .gindoq {
    border-radius: 12px;
  }
  .eghPaz {
    width: 50%;
  }
  .hYVpLi {
      margin-top: -4px;
      margin-bottom: -4px;
  }
  }
  @media screen and (min-width: 64em)
  {
    .jKftlo {
      width: 50%;
  }
  .stlTT {
    font-size: 14px;
  }
  .hYVpLi {
      margin-left: -8px;
      margin-right: -8px;
  }
  }
  .fjzJdU {
      position: relative;
      top: 16px;
      left: 14%;
  }
  .dRlhbD {
    z-index: 5;
  }
  .dUAERr {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media screen and (min-width: 40em)
  {
  .dUAERr {
    padding-top: 4px;
    padding-bottom: 4px;
  }}
  @media screen and (min-width: 64em)
  {
  .dUAERr {
    width: 66%;
    padding-left: 30px;
    padding-right: 8px;
  }}
  .heAqDO {
    display: flex;
    flex-direction: column;
  }
  .eVekry {
    margin-bottom: 32px;
    width: 100%;
  }
  .fxJeHc {
    margin-bottom: 0px;
  }
  @media screen and (min-width: 64em)
  {
  .eVekry {
    width: 66.6667%;
  }
  .fxJeHc {
      margin-bottom: 32px;
  }
  }
  
  .fbmvGi {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-family: inherit;
    cursor: pointer;
    border-width: 0px;
    border-style: solid;
    border-radius: 9999px;
    font-size: 14px;
    -webkit-font-smoothing: inherit;
    font-weight: 500;
    color: rgb(0, 104, 239);
    line-height: 1.4;
    vertical-align: inherit;
    background-color: transparent;
    margin-right: 0px;
    width: 100%;
    padding: 0px;
  }
  .cgaCzu {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dVmLzM {
      display: block;
  }
 
  .dcGJoN{
    width: auto !important;
  }
  .cnQMIQ {
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 100;
  }
  .fNNQQL {
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 700;
  }
  
  .gFVzYR:hover {
    text-decoration: none;
  }
  .ctTVKt {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
  }
  .jpUEwj {
    margin-top: 2px;
    padding-right: 4px;
  }
  .jYPJRh {
    display: block;
    width: 100%;
    margin: 0px;
    color: rgb(0, 24, 51);
    font-size: 12px;
    font-weight: normal;
    line-height: 1.4;
    letter-spacing: 0.2px;
  }
  .gQifjs {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    vertical-align: middle;
    padding: 2px;
    cursor: pointer;
    background-color: inherit;
    color: rgb(94, 104, 114);
  }
  .geBRaV {
    appearance: none;
    opacity: 0;
    position: absolute;
    z-index: 0;
  }
  .gQifjs svg[data-name="checked"] {
    display: none;
  }
  .gQifjs svg {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 2px;
  }
  .fQHOkP {
    outline: none;
  }
  .gQifjs svg[data-name="indeterminate"] {
    display: none;
  }
  .ligbEt {
    flex: 0 0 auto;
    line-height: 1;
    width: 24px;
  }
  .ehVqsH {
    outline: none;
  }
  .gQifjs > input:checked:not([data-indeterminate="true"]) ~ svg[data-name="checked"] {
    display: inline-block;
    color: rgb(0, 104, 239);
  }
  .gQifjs svg[data-name="checked"] {
    display: none;
  }
  .gQifjs > input:checked:not([data-indeterminate="true"]) ~ svg[data-name="empty"] {
    display: none;
  }
  .ffVUga {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 4px;
  }
  .kULWIA {
    height: 47px;
  }
  .kNhhGi {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.5;
    cursor: pointer;
    border-width: 0px;
    border-style: solid;
    border-radius: 12px;
    font-size: 16px;
    background-color: rgb(0, 104, 239);
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 0px 4px;
  }
  .ezgmJW {
    padding-top: 8px;
    margin-top: 4px;
    text-align: center;
  }
  .eUJElH {
    font-size: 12px;
  }
  .fhWJzE {
    border-top: 1px solid rgb(192, 202, 213);
  }
  .gGkbt {
    padding: 16px;
  }
  .VdGuR {
    font-size: 14px;
  }
  .iCdfOp {
    width: 100%;
    border-radius: 2px;
  }
  .eGMgXl {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .eewULi {
    cursor: pointer;
    text-decoration: none;
    color: rgb(0, 104, 239);
  }
  .hxzNjF {
    border-width: 0px;
    border-radius: 24px;
    border-color: rgb(192, 202, 213);
    border-style: solid;
  }
  .cZVRZb {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .fnUlhM {
    margin-top: -8px;
  }
  .dbIoGf {
    z-index: -1;
    position: relative;
  }
  @media screen and (min-width: 40em)
  {
    .ffVUga {
      width: 50%;
  }
  .dVmLzM {
      display: block;
  }
  .cZVRZb {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  }
  @media screen and (min-width: 48em)
  {
    .jhIZHl {
      min-width: 720px;
  }
  .fbmvGi {
      margin-right: 16px;
      width: auto;
      padding-right: 16px;
  }
  
  }
  
  @media screen and (min-width: 64em)
  {
    .jhIZHl {
      margin-left: 0px;
      margin-right: 0px;
  }
  .fnUlhM {
    margin-top: 0px;
  }
    .ffVUga {
      width: 50%;
  }
  }
  
  @media screen and (min-width: 32em)
  {
    .jpUEwj {
      padding-right: 8px;
  }
    .stlTT {
      padding-right: 8px;
  }
  .ikqVgf {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
  }
  }
  .ikqVgf {
    color: rgb(0, 60, 138);
    font-weight: 700;
    text-align: left;
    margin: 0px 32px;
    padding-left: 0px;
    padding-bottom: 16px;
    padding-right: 0px;
    font-size: 36px;
    line-height: 40px;
  }
  .jsuRSV {
    text-align: left;
    margin: 0px 32px;
    padding-left: 0px;
    padding-bottom: 16px;
    padding-right: 0px;
  }
  .tSWaN {
    width: 72%;
    height: 212px;
  }
  @media screen and (min-width: 48em)
  {
  .tSWaN {
      height: 240px;
  }
  .ikqVgf {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
  }
  }
  div {
    display: block;
  }
  
  @media screen and (min-width: 64em) and (max-width: 89.99em)
  {
    .rSqYC {
        display: none;
    }
  
  }
  .jixyDq:hover {
    color: rgb(0, 104, 239) !important;
    text-decoration: underline;
  }
  
  /* .dVmLzM:hover {
    color: rgb(0, 104, 239) !important;
    text-decoration: none;
    border: 1px solid rgb(0, 68, 153) ;
  } */
  .jhFlZt:hover {
    color: rgb(0, 68, 153) !important;
    text-decoration: underline;
  }
  .gFVzYR:hover {
    text-decoration: none;
  }
  /* .bNDgsd:active{
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid rgb(0, 104, 239);
    background-color: rgb(232, 242, 255);
    border-radius: 9999px
  } */
  .fUmNib {
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid rgb(0, 104, 239);
    background-color: rgb(232, 242, 255);
    border-radius: 9999px;
  }
  .iTlgTb:active{
    background: transparent;
  }
  .transfer .react-datepicker__input-container{
    height: 56px;
  }
  .transfer .react-datepicker__input-container input{
    margin-left: 35px;
    margin-top: 20px;
  }

  .clRfgG {
    border-radius: 9999px;
  }
  .bLIJfH {
    padding-top: 2px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .kDxMca {
    display: inline-block;
  }
  .eUlJqB {
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 100;
  }
  .kmgYlY {
    border-radius: 2px;
  }
  .fgRulP {
    max-width: 1280px;
  }
  .bXYFop {
    border-width: 0px;
    border-color: rgb(192, 202, 213);
    border-radius: 2px;
    border-style: solid;
  }
  .kmoYRN {
    border-radius: 24px 24px 6px 6px;
  }
  /* :not(svg) {
    transform-origin: 0px 0px;
  } */
  @media screen and (min-width: 64em)
  {
    .fjzJdU {
        left: 28%;
    }
    .cZVRZb {
      width: 33%;
      padding-left: 8px;
      padding-right: 8px;
  }
    .ikqVgf {
      padding-bottom: 32px;
      padding-right: 130px;
      margin-left: 0px;
      margin-right: 0px;
    }
   .tSWaN {
      height: 420px;
  }}
  @media screen and (min-width: 48em) and (max-width: 63.99em)
  {
  .hKHkrd {
      display: none;
  }
  }
  @media screen and (max-width: 48em)
  {
  .hKHkrd {
      display: none;
  }
  }
  .jVAcwv {
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.4;
    font-weight: 500;
  }
/* ********************************
***********************************
           Other Components CSS
***********************************
********************************* */
#global-header { height: 50px; } 
   @media (min-width: 768px) { 
    #global-header {
       height: 60px; } } 
  .node-invisible { 
    display: none !important; }
   #pcln-global-header {
     background: #0068ef; 
     min-width: 0; 
     z-index: 1500; 
     visibility: visible !important; } 
   #pcln-global-header .navbar { 
    border-radius: 0; 
    margin-bottom: 0; 
    border: none; 
    -webkit-font-smoothing: initial; 
    -moz-osx-font-smoothing: initial; } 
    #pcln-global-header .navbar-priceline { 
      font-size: 12.8px; 
      overflow: visible; 
      height: 50px; 
      width: 100%; 
      font-family: Montserrat, "Arial Regular", sans-serif; 
      font-weight: 100; 
      color: #FFFFFF; 
      box-sizing: border-box; 
      min-width: 320px; 
      max-width: 1280px; 
      margin: 0 auto; } 
      @media (min-width: 768px) { 
        #pcln-global-header .navbar-priceline { 
          height: 100px; 
        } } 
      #pcln-global-header .navbar-priceline-brand { 
        position: relative; 
        vertical-align: top; 
        float: none !important; 
        display: inline-block; 
        z-index: 1; 
        height: 22px; 
        width: 89px; 
        top: -3px; 
        margin-left: 20px; } 
      @media (max-width: 319px) { 
        #pcln-global-header .navbar-priceline-brand { 
          display: none; } } 
      @media (min-width: 768px) { 
        #pcln-global-header .navbar-priceline-brand { 
         left: 24px; top: 10px; margin-left: 0;
         } }
        #pcln-global-header .navbar-header { 
          height: 50px; 
          box-sizing: border-box; 
          width: 100%;
        }
        #pcln-global-header .dropdown-section .sign-in-group:hover .global-header-dropdown-list, #pcln-global-header .dropdown-section .sign-in-group:active .global-header-dropdown-list, #pcln-global-header .dropdown-section .my-trips-group:hover .global-header-dropdown-list, #pcln-global-header .dropdown-section .my-trips-group:active .global-header-dropdown-list, #pcln-global-header .dropdown-section .help-group:hover .global-header-dropdown-list, #pcln-global-header .dropdown-section .help-group:active .global-header-dropdown-list, #pcln-global-header .dropdown-section .default-currency-d-mc:hover .global-header-dropdown-list, #pcln-global-header .dropdown-section .default-currency-d-mc:active .global-header-dropdown-list, #pcln-global-header .dropdown-section .default-language-d-lang:hover .global-header-dropdown-list, #pcln-global-header .dropdown-section .default-language-d-lang:active .global-header-dropdown-list {
          visibility: visible;
          opacity: 1;
      }
        #pcln-global-header .navbar-header .primary-nav-group { overflow: visible; position: relative; max-height: 60px; height: 50px; padding: 0 !important; /* style to override and override in stay checkout */ } 
        @media (min-width: 768px) { #pcln-global-header .navbar-header .primary-nav-group { height: 60px; } } 
        /* #pcln-global-header .navbar-header .primary-nav-group .nav-section { position: absolute; width: 100%; height: 100%; }  */
        #pcln-global-header .navbar-header .primary-nav-group .primary-nav-font { font-weight: 100; font-family: Montserrat, "Arial Regular", sans-serif; font-size: 14px; position: relative; top: -4px; margin-left: 4px; margin-right: 5px; display: none; }
         @media (min-width: 375px) { #pcln-global-header .navbar-header .primary-nav-group .primary-nav-font { margin-right: 10px; } } 
         @media (min-width: 1024px) { #pcln-global-header .navbar-header .primary-nav-group .primary-nav-font { display: inline-block; } } 
         #pcln-global-header .navbar-header .primary-nav-group .dropdown-section .sign-in-group .signed-in-greeting .primary-nav-font, #pcln-global-header .navbar-header .primary-nav-group .dropdown-section .sign-in-group .user-avail-greeting .primary-nav-font { display: inline-block; } 
         #pcln-global-header .navbar-hamburger { visibility: visible !important; width: 28px; height: 22px; margin: 14px -5px 14px 15px; 
          /* background: url(https://s1.pclncdn.com/design-assets/gns/hamburger-white.svg) no-repeat;  */
          display: inline-block; } @media (min-width: 769px) { #pcln-global-header .navbar-hamburger { display: none; } } 
          #pcln-global-header .navbar-hamburger:active .meat { fill: #9fddff; } 
          #pcln-global-header .navbar-hamburger:hover { cursor: pointer; } 
          #pcln-global-header .global-header-nav-product-list { position: relative; display: none; top: calc(50% - 9px); left: 0px; margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; } 
          @media (min-width: 769px) { #pcln-global-header .global-header-nav-product-list { display: inline-table; margin: 0 auto; width: 70%; text-align: center; font-size: 14px; box-sizing: border-box; } }
          @media (min-width: 768px) { #pcln-global-header .global-header-nav-product-list { top: calc(50% - 30px); width: 100%; margin: 0 auto; text-align: left; padding-right: 80px; padding-left: 170px; margin-top: -22px;} } 
          #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item { display: inline; } 
          @media all and (min-width: 1200px) { #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-hotels { margin-left: 41px; } } 
          @media all and (min-width: 1024px) and (max-width: 1199px) { #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-hotels { margin-left: 41px; } }
          @media all and (min-width: 910px) and (max-width: 1023px) { #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-hotels { margin-left: 41px; } } 
          @media all and (max-width: 909px) { #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-hotels { margin-left: 31px; } } 
          #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-cars, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-flights, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-packages, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-cruises, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-experiences, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-groups { margin-left: 24px; } 
          @media all and (max-width: 909px) { #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-cars, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-flights, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-packages, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-cruises, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-experiences, #pcln-global-header .global-header-nav-product-list .global-header-nav-product-item-groups { margin-left: 16px; } } 
          #pcln-global-header .global-header-nav-product-list .global-header-nav-product-link { color: #FFFFFF; text-decoration: none; font-size: 14px; letter-spacing: 0.5px; } 
          #pcln-global-header .global-promo-banner { font-family: 'Montserrat', sans-serif; display: flex; justify-content: center; font-size: 14px; } @media (max-width: 768px) { #pcln-global-header .global-promo-banner 
          { font-size: 12px; padding: 0px 16px 0px 16px; } } 
          #pcln-global-header .global-promo-banner .global-promo-banner-text { max-width: 1216px; text-align: center; margin: auto; padding: 12px; } 
          #pcln-global-header .global-promo-banner .global-promo-banner-headline { font-weight: 700; } 
          @media (max-width: 768px) { #pcln-global-header .global-promo-banner .global-promo-banner-headline { display: inline-block; width: 100%; margin-bottom: 4px; } } 
          #pcln-global-header .global-promo-banner .global-promo-banner b { font-weight: 700; } 
          #pcln-global-header .global-promo-banner .global-promo-banner-subheadline { font-weight: 500; display: inline-block; } 
          #pcln-global-header .global-promo-banner .global-promo-banner-subheadline a { color: inherit; text-decoration: underline; } 
          @media (max-width: 768px) { #pcln-global-header .global-promo-banner .not-mobile { display: none; } } 
          @media (min-width: 769px) { #pcln-global-header .global-promo-banner .on-mobile-only { display: none; } } 
          #pcln-global-header .global-promo-banner .global-promo-banner:hover { cursor: default; } 
          @keyframes fadeIn { 0% { opacity: 0; } 25% { opacity: 1; } 75% { opacity: 1; } 100% { opacity: 0; } } 
          @-webkit-keyframes fadeIn { 0% { opacity: 0; } 25% { opacity: 1; } 75% { opacity: 1; } 100% { opacity: 0; } } 
          #newTooltipSection { font-family: Montserrat, "Arial Regular", sans-serif !important; display: none; position: absolute; top: 5px; z-index: 111 !important; width: 88%; font-weight: normal !important; font-size: 14px !important; height: auto; background-color: white; color: black; border: 1px solid #BECAD6 !important; text-align: left; padding: 0 16px 16px 16px !important; border-radius: 5px; box-shadow: 0 3px 10px darkgrey; }
           @media (min-width: 768px) { #newTooltipSection { width: 400px; } } 
           @media (min-width: 769px) { #newTooltipSection { top: 9px; } } 
           #newTooltipSection h3, #newTooltipSection b { font-weight: 700; } 
           #newTooltipSection i { font-style: italic; } #newTooltipSection::before { content: ""; position: relative; top: -22px; left: 45%; border-width: 12px; border-style: solid; border-color: transparent transparent white transparent; } 
           #hideToolTipButton { float: right; position: absolute; right: 0; font-size: 1.75em; margin-left: 1em; margin-right: 8px; margin-top: 4px; border: 0; background: transparent; }
           #hideToolTipButton:hover { cursor: pointer; } #hideToolTipButton:active { margin-left: 1px 1px 0; -moz-outline-radius: 2px; background-image: linear-gradient(to top, #f4f5f5, #dfdddd); } 
           #more-info-toggle { position: relative; z-index: 1; text-decoration: underline; cursor: pointer; color: white; font-size: 14px; border: 0; background: transparent; font: inherit; } 
           @media (max-width: 599px) { #pcln-global-header .header-redesign .navbar-priceline-brand { margin-left: 13px; } }    body { background-color: transparent !important; } 
           #pcln-global-header { background: #fefefe; box-shadow: 0px 1px 0px 0px rgba(89, 106, 125, 0.5); }
            #pcln-global-header .navbar-priceline.light-header { max-width: 1440px; } 
           #pcln-global-header .light-header .navbar-hamburger { width: 56px; height: 50px; border-right: 1px solid rgba(89, 107, 125, 0.5); margin: auto; margin-right: -5px; display: inline-flex; justify-content: center; } 
           #pcln-global-header .light-header .navbar-hamburger img { width: 28px; } @media (min-width: 769px) { #pcln-global-header .light-header .navbar-hamburger { display: none; } } 
           #pcln-global-header .light-header .hamburger-section__header { background: #fefefe; } 
           #pcln-global-header .light-header .hamburger-section__header-title { color: #0068ef; font-weight: normal; } 
           @media (min-width: 768px) { #pcln-global-header .light-header .navbar-priceline-brand { left: 24px; top: -10px; } } 
           #pcln-global-header .light-header .navbar-priceline-brand:focus { outline: 1px dotted #212121; outline: -webkit-focus-ring-color auto 5px; } 
           #pcln-global-header .light-header .global-header-nav-product-list .global-header-nav-product-link { font-family: Montserrat, "Arial Regular", sans-serif; color: #556b7f; letter-spacing: 0; } 
           #pcln-global-header .light-header .global-header-nav-product-list .global-header-nav-product-link:focus { outline: 1px dotted #212121; outline: -webkit-focus-ring-color auto 5px; } 
           @media (min-width: 320px) and (max-width: 767px) { #pcln-global-header .header-redesign .signed-in-greeting #sign-in-module__user-name-signedIn { position: inherit; } } 
           @media (min-width: 320px) { #pcln-global-header .light-header .navbar-header .dropdown-section #multi-currency-module .primary-nav-font, #pcln-global-header .light-header .navbar-header .dropdown-section #language-module .primary-nav-font { display: inline-block; } } 
           @media (min-width: 768px) and (max-width: 1023px) { #pcln-global-header .light-header .navbar-header .dropdown-section #multi-currency-module, #pcln-global-header .light-header .navbar-header .dropdown-section #language-module { margin-right: 0px; margin-left: 0px; } } 
          #pcln-global-header .light-header .navbar-header .dropdown-section .vip-badge { display: none; } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design { font-size: 0; } 
          #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.my-trips-dropdown, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.help-dropdown {
            width: 264px;
        }
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .global-header-dropdown-list { font-size: 12.8px; } 
          /* #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .primary-nav-font { margin-left: 0px; }  */
          @media (min-width: 768px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design { top: 3px; } } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .nav-icon { display: inline-block; } 
          @media (min-width: 1024px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .nav-icon { display: none; } } 
          @media (min-width: 1024px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .btn-arrow:after { top: 24px; } } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #currency-label { display: none; } 
          @media (min-width: 1024px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #currency-label { display: inline-block; } } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #currency-symbol { display: none; } 
          @media (min-width: 768px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #currency-symbol { display: inline-block; } } @media (min-width: 1024px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #currency-symbol { display: none; } } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .btn-priceline { padding-left: 0px; } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .btn-priceline#in-path-multi-currency-click, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .btn-priceline#in-path-language-click { padding-left: 0px; } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #multi-currency-module, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #language-module { min-width: auto; margin-left: 0px; } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .my-trips-group, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .help-d-mc, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .help-f-mc, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #multi-currency-module { margin-right: 10px; } 
          @media (min-width: 768px) and (max-width: 1023px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .my-trips-group, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .help-d-mc, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .help-f-mc, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #multi-currency-module { margin-right: 20px; } } 
          @media (min-width: 1024px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .my-trips-group, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .help-d-mc, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .help-f-mc, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design #multi-currency-module { margin-right: 16px; } } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .signed-in-greeting .primary-nav-font, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .user-avail-greeting .primary-nav-font { display: none; } 
          @media (min-width: 1024px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .signed-in-greeting .primary-nav-font, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .user-avail-greeting .primary-nav-font { display: inline-block; } } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .default-currency-f-mc { display: none; } @media (min-width: 768px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .default-currency-f-mc { display: inline-block; } } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge { display: inline-block; margin-right: 10px; } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge .btn-priceline { padding-right: 0px; } 
          @media (min-width: 321px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge { margin-right: 18px; } } 
          @media (min-width: 768px) and (max-width: 1023px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge { margin-right: 20px; } }
           @media (min-width: 1024px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge { margin-right: 16px; } } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge > .navbar-btn { font-size: 0; display: inline-block; } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge .vip-badge-icon { position: relative; top: 3px; } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge .nav-title { margin-right: 0; margin-left: 8px; } 
          @media (min-width: 320px) and (max-width: 767px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge .nav-title { display: inline-block; } } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .vip-badge .nav-title.tier-label { text-transform: uppercase; font-size: 12px; font-weight: 700; } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .btn-priceline .anonymous-greeting .sign-in-title-mobile-text { display: none; } 
          @media (min-width: 320px) and (max-width: 767px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group #in-path-sign-in-out-click .icon-utility-svg, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group #in-path-sign-in-out-click .icon-utility-profile { position: relative; top: 3px; margin-right: 12px; background-size: 24px 24px; height: 24px; width: 24px; } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .signed-in-greeting .icon-utility-profile, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .user-avail-greeting .icon-utility-profile { display: none; } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .signed-in-greeting #sign-in-module__user-name-signedIn { display: inline-block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; } 
          #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .signed-in-greeting #sign-in-module__user-name-signedIn > .user-greeting { display: none; } } 
          @media (max-width: 320px) { #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group #in-path-sign-in-out-click .icon-utility-svg, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group #in-path-sign-in-out-click .icon-utility-profile { margin-right: 4px; }
           #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group #in-path-sign-in-out-click .icon-utility-profile { display: inline-block; } #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group .signed-in-greeting #sign-in-module__user-name-signedIn { display: none; } }
            #pcln-global-header .header-left-icon-container { display: flex; align-items: center; justify-content: center; } 
           #pcln-global-header .header-redesign .navbar-hamburger { border-color: transparent; float: left; } 
           #pcln-global-header .header-redesign .navbar-hamburger { display: flex; align-items: center; justify-content: center; } #pcln-global-header .header-redesign .navbar-hamburger .header-left-icon-container { background-color: #EDF0F3; border-radius: 40px; width: 40px; height: 40px; } 
           @media (min-width: 769px) { #pcln-global-header .header-redesign .navbar-hamburger { display: none; } } 
           #pcln-global-header .header-redesign .navbar-hamburger img { width: 24px; } .phone-ua #pcln-global-header { top: auto; }    #pcln-global-header .global-promo-banner { font-family: 'Montserrat', sans-serif; display: flex; justify-content: center; font-size: 14px; -webkit-font-smoothing: antialiased; width: 100%; } 
           @media (max-width: 768px) { #pcln-global-header .global-promo-banner { font-size: 12px; padding: 0px 16px 0px 16px; } }
            #pcln-global-header .global-promo-banner .global-promo-banner-wrapper { position: relative; width: 100%; height: 100%; } 
           #pcln-global-header .global-promo-banner .global-promo-banner-wrapper a { position: absolute; width: 100%; height: 100% } 
           #pcln-global-header .global-promo-banner .global-promo-banner-text { max-width: 1216px; text-align: center; margin: auto; padding: 12px; } 
          #pcln-global-header .global-promo-banner .global-promo-banner-headline { font-weight: 700; margin-right: 32px; } 
          @media (max-width: 768px) { #pcln-global-header .global-promo-banner .global-promo-banner-headline { display: inline-block; width: 100%; margin-bottom: 4px; } } 
          #pcln-global-header .global-promo-banner .global-promo-banner-subheadline { font-weight: 500; display: inline-block; } 
          #pcln-global-header .global-promo-banner .global-promo-banner-subheadline a { color: inherit; text-decoration: underline; } 
          @media (max-width: 768px) { #pcln-global-header .global-promo-banner .not-mobile { display: none; } } 
          @media (min-width: 769px) { #pcln-global-header .global-promo-banner .on-mobile-only { display: none; } } 
          #pcln-global-header .global-promo-banner .global-promo-banner:hover { cursor: default; } 
          #newTooltipSection { font-family: Montserrat, "Arial Regular", sans-serif !important; display: none; position: absolute; top: 5px; z-index: 111 !important; width: 88%; font-weight: normal !important; font-size: 14px !important; height: auto; 
            background-color: white; color: black; border: 1px solid #BECAD6 !important; text-align: left; padding: 0 16px 16px 16px !important; border-radius: 5px; box-shadow: 0 3px 10px darkgrey; } 
          @media (min-width: 769px) { #newTooltipSection { width: 400px; top: 9px; } } 
          #newTooltipSection h3, #newTooltipSection b { font-weight: 700; } 
          #newTooltipSection i { font-style: italic; } 
          #newTooltipSection::before { content: ""; position: relative; top: -22px; left: 45%; border-width: 12px; border-style: solid; border-color: transparent transparent white transparent; } 
          #hideToolTipButton { float: right; position: absolute; right: 0; font-size: 1.75em; margin-left: 1em; margin-right: 8px; margin-top: 4px; border: 0; background: transparent; } 
          #hideToolTipButton:hover { cursor: pointer; } 
          #hideToolTipButton:active { margin-left: 1px 1px 0; -moz-outline-radius: 2px; background-image: linear-gradient(to top, #f4f5f5, #dfdddd); } 
          #more-info-toggle { position: relative; z-index: 1; text-decoration: underline; cursor: pointer; color: white; font-size: 14px; border: 0; background: transparent; font: inherit; } 
          #more-info-wrapper { display: flex; justify-content: center; position: relative; } 
          #more-info-headline { display: flex; justify-content: space-between; align-items: baseline; margin-top: inherit; } 
          #more-info-headline h3 { text-align: center; font-size: 16px; margin-bottom: 12px; color: black; } 
          #more-info-link { display: block; font-size: 14px; font-weight: 700; text-decoration: none; color: #0068EF; margin-top: 16px; }
          #pcln-global-header #global-header {
            visibility: visible!important;
            padding-top: 0px;
        }
        #pcln-global-header .header-redesign .nav-section, #pcln-global-header .header-redesign .global-header-nav-product-item, #pcln-global-header .header-redesign .global-header-nav-product-link {
          color: #001832!important;
      }
      #pcln-global-header *, #pcln-global-header *:before, #pcln-global-header *:after, #pcln-global-header:before, #pcln-global-header:after {
        box-sizing: border-box;
    }
@media (min-width: 768px){
#pcln-global-header .light-header .navbar-hamburger {
    height: 50px!important;
    background-color: #fff;
}}
@media (min-width: 600px){
#pcln-global-header .header-redesign .dropdown-section.vip-design {
    top: 5px!important;
}}
#pcln-global-header .header-redesign .dropdown-section {
    display: inline-flex;
}
#pcln-global-header .dropdown-section {
    visibility: visible!important;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    text-align: right;
    display: inline-block;
    width: auto;
}
#pcln-global-header *, #pcln-global-header *:before, #pcln-global-header *:after, #pcln-global-header:before, #pcln-global-header:after {
    box-sizing: border-box;
}
#pcln-global-header .dropdown-section .list-item-group {
  position: relative;
}
#pcln-global-header .dropdown-section>* {
  cursor: pointer;
  height: 100%;
  display: inline-block;
  text-align: left;
  border-bottom: 8px solid transparent;
}
#pcln-global-header .dropdown-section .default-currency-no-mc, #pcln-global-header .dropdown-section .default-language-no-lang {
  display: none;
}
#pcln-global-header .dropdown-section .btn-priceline {
  color: #fff;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 7px 6px 0;
    transition: all -2.8s ease;
    height: 100%;
    font-size: 12.8px;
    position: relative;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  }
  #pcln-global-header .header-redesign .dropdown-section .btn-priceline {
    color: #001832!important;
    padding-right: 0;
  }
  #pcln-global-header .light-header .dropdown-section .btn-priceline, #pcln-global-header .light-header .dropdown-section .sign-in-group .btn-priceline .anonymous-greeting .sign-in-title-mobile {
      color: #0068ef;
  }
  #pcln-global-header .header-redesign .dropdown-section .btn-priceline:hover {
    color: #0068ef!important;
}
/* #pcln-global-header .header-redesign .dropdown-section .icon-utility-trips {
  background: url(data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 24 24%22 fill=%22none%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M20 6.5H16V4.5C16 3.4 15.1 2.5 14 2.5H10C8.9 2.5 8 3.4 8 4.5V6.5H4C2.9 6.5 2 7.4 2 8.5V19.5C2 20.6 2.9 21.5 4 21.5H20C21.1 21.5 22 20.6 22 19.5V8.5C22 7.4 21.1 6.5 20 6.5ZM14 6.5H10V4.5H14V6.5Z%22 fill=%22%230068EF%22/%3E%3C/svg%3E);
} */
  @media (min-width: 1024px)
  {
  #pcln-global-header .header-redesign .dropdown-section .btn-priceline {
      padding-right: 6px;
  }
  }
  #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.mc-dropdown, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.lang-dropdown {
    width: 560px;
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 8px;
}
#pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.my-trips-dropdown, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.help-dropdown, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.mc-dropdown, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.lang-dropdown {
  margin-top: -3px;
  margin-right: -20px;
  background-color: #fff;
  box-shadow: 0 8px 32px #0000003d, 0 8px 16px #0003, 0 24px 64px #0003;
  border-radius: 24px;
  padding: 16px;
}
#pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.mc-dropdown {
    margin-right: -137px;
}
#pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list {
    border-radius: 24px;
    padding: 18px 20px;
}
#pcln-global-header .dropdown-section .global-header-dropdown-list {
    background: #f2f2f2;
    line-height: 1.25em;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    padding: 0 10px;
    -moz-box-shadow: rgba(0,0,0,.2) 0 2px 2px 0;
    -webkit-box-shadow: rgba(0,0,0,.2) 0 2px 2px 0;
    box-shadow: #0003 0 2px 2px;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    cursor: pointer;
    position: absolute;
    width: 190px;
    margin-top: 8px;
}
#pcln-global-header .dropdown-section .global-header-dropdown-list {
    right: 0;
}
#pcln-global-header ul {
    margin: 0;
    padding: 0;
}
@media (min-width: 768px)
{
#pcln-global-header .dropdown-section .help-no-mc {
    margin-right: 24px;
}}
#pcln-global-header .dropdown-section .help-group {
  display: none;
}
@media (min-width: 768px)
{#pcln-global-header .dropdown-section .help-group {
    display: inline-block;
}}
#pcln-global-header .dropdown-section .my-trips-group {
  display: none;
}
@media (min-width: 768px)
{#pcln-global-header .dropdown-section .my-trips-group {
  display: inline-block;
  margin-right: 10px;
}}
#pcln-global-header .dropdown-section .global-header-dropdown-item, #pcln-global-header .dropdown-section .global-header-dropdown-item-mc, #pcln-global-header .dropdown-section .global-header-dropdown-item-lang {
  border-bottom: 1px solid;
  border-color: #d8d8d8;
  padding: 0 5px;
  display: block;
  background: #f2f2f2;
}
#pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item-mc, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item-lang {
  border: none;
  background-color: #fff;
  padding: 0;
}
#pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item .global-header-dropdown-link, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item-mc .global-header-dropdown-link, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item-lang .global-header-dropdown-link {
  font-size: 14px;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 8px;
  background-color: #fff;
  color: #001832!important;
}
#pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.my-trips-dropdown .global-header-dropdown-item:first-child .global-header-dropdown-link, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list.help-dropdown .global-header-dropdown-item:first-child .global-header-dropdown-link {
  background-color: #0068ef;
  color: #fff!important;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 999px;
}
#pcln-global-header .dropdown-section .global-header-dropdown-link {
  display: block;
  padding: 18px 0 16px;
}
#pcln-global-header .global-header-dropdown-link {
  display: block;
  padding: 18px 0 16px;
}
#pcln-global-header a {
  background-color: transparent;
  color: #337ab7;
  text-decoration: none;
}
#pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item:last-child .global-header-dropdown-link, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item-mc:last-child .global-header-dropdown-link, #pcln-global-header .header-redesign .dropdown-section .global-header-dropdown-list .global-header-dropdown-item-lang:last-child .global-header-dropdown-link {
  margin-bottom: 0;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu {
  display: inline-table;
  width: 360px;
  height: 78px;
  background-color: #085397;
  position: absolute;
  top: 50px;
  left: auto;
  right: 0;
  margin: 0;
  padding: 0;
  box-shadow: 2px 2px 2px #00000052;
  font-weight: 300;
}
@media (min-width: 768px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu {
    top: 60px;
}}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-graphic {
  width: 50px;
  height: 50px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 0 0 15px;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-graphic-outer {
  width: 48.7px;
  height: 48.7px;
  background-color: #fff3;
  border-radius: 50px;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-graphic-outer-inner {
  width: 36.9px;
  height: 36.9px;
  background-color: #ffffff59;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  top: 6px;
  left: 6px;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-graphic-outer-inner-glyph {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-graphic-outer-inner-glyph .icon-utility-unlock {
  position: absolute;
  right: 11px;
  top: 10px;
  font-size: large;
}

#pcln-global-header .dropdown-section .icon-utility-unlock {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: -4px;
  margin-top: -4px;
  /* background: url(data:image/svg+xml;charset=UTF-8,%3Csvg fill=%22%23FFFFFF%22 height=%2224%22 viewBox=%220 0 24 24%22 width=%2224%22 xmlns=%22http://www.w3.org/2000/svg%22%3E%3Cpath d=%22M0 0h24v24H0z%22 fill=%22none%22/%3E%3Cpath d=%22M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z%22/%3E%3C/svg%3E); */
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-deals-ad {
  display: table-cell;
  width: 137px;
  padding: 14px 0 0;
  font-size: 14px;
  position: absolute;
  left: 78px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 300;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-deals-ad-bold-prices {
  font-size: 16px;
  line-height: 1.1;
  color: #fff;
  display: block;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-in-menu-Wahl {
  display: table-cell;
  float: right;
  width: 110px;
  height: 35px;
  margin: 22px 15px 0 0;
  border: solid 1px #fff;
  font-size: 14px;
  color: #fff;
  text-align: center;
  padding: 7px 0;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 300;
}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu {
  top: 50px;
}
@media (min-width: 768px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu {
  top: 60px;
}}
@media (min-width: 320px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu {
  right: 0;
  left: auto;
  margin: 0;
  top: 50px;
  width: 238px;
}}
@media (min-width: 1024px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu {
  left: 0;
  width: 360px;
}}
#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu {
  display: block;
  left: auto;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu {
  cursor: initial;
  background-color: #fff;
  box-shadow: 0 0 2px #00000014, 0 2px 8px #00000029;
  top: 50px;
  font-weight: 500;
  border-radius: 2px;
}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-greeting {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-greeting {
  display: block;
  height: 23px;
  width: 203px;
  font-size: 16px;
  line-height: 22px;
  color: #001832;
  margin: 8px 0 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-first-name {
  display: inline-block;
  text-transform: lowercase;
}
@media (min-width: 320px)
{#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-greeting {
    margin: 0;
}}
@media (min-width: 1024px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-greeting {
    width: 266px;
}}
@media (min-width: 320px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-greeting {
    margin: 20px 0 0 10px;
}}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-email {
  width: 100%;
  margin: 6px 0;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-email {
  position: relative;
  display: block;
  width: 197px;
  height: 36px;
  margin: 5px 0 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #001832;
  font-size: 12px;
  line-height: 17px;
}
@media (min-width: 1024px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-email {
  width: 200px;
}}
@media (min-width: 320px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-email {
  height: 28px;
}}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-button {
  position: relative;
  top: 0px;
  width: 100%;
  right: 0px;
  margin: 0;
  height: 40px;
  padding: 11px 0;
  border: none;
  background-color: #0068ef;
  font-weight: 700;
  border-radius: 999px;
}

#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-button {
  display: inline-block;
  width: 100px;
  height: 35px;
  border: solid 1px #0077FF;
  padding: 7px 0;
  text-align: center;
  margin: 0 25% 14px;
  border-radius: 2px;
}
@media (min-width: 1024px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-button {
  position: absolute;
  top: 28px;
  right: 20px;
  margin: 0;
}}
#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-button-text {
  color: #0068ef;
  font-size: 12px;
}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-button .sign-out-menu-button-text {
  color: #fff;
  font-size: 14px;
}
#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-hr {
  background-color: #c0cad5;
  margin: 10px;
}
@media (min-width: 320px)
{#pcln-global-header .dropdown-section .sign-in-group .sign-out-menu-hr {
  margin-top: 0;
}}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-hr {
  margin: 10px 0;
}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-button.sign_out {
  float: none;
  text-align: center;
  background-color: #edf0f3;
}
#pcln-global-header .header-redesign .dropdown-section .sign-in-group .sign-out-menu-button.sign_out .sign-out-menu-button-text {
  color: #0068ef;
}

#pcln-global-header .header-redesign .dropdown-section>div:last-child {
  margin-right: 8px!important;
}
#pcln-global-header .dropdown-section>div:last-child {
  margin-right: 27px;
}
@media (min-width: 768px)
{#pcln-global-header .header-redesign .dropdown-section>div:last-child {
    margin-right: 18px!important;
}}
#pcln-global-header .dropdown-section .navbar-btn {
  cursor: pointer;
}
#pcln-global-header .header-redesign .dropdown-section .vip-badge {
  background-color: #edf0f3;
  height: 40px;
  border-radius: 999px;
  padding-right: 5px;
  padding-left: 12px;
  border: 1px solid transparent;
  margin-top: 5px;
  margin-right: 0!important;
}
#pcln-global-header .header-redesign .dropdown-section .vip-badge .nav-title {
  display: inline;
  color: #0068ef;
  top: 5px;
  font-weight: 700;
  font-family: Montserrat, "Arial Regular", sans-serif;
  font-size: 12px;
  margin-left: 0!important;
  margin-right: 15px!important;
}

#pcln-global-header .header-redesign .dropdown-section .vip-badge .vip-badge-icon {
  top: 4px!important;
}
#pcln-global-header .slide-down-animation, #pcln-global-header .in-path-authorization-outlet {
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height .5s ease-in-out;
  -webkit-transition: height .5s ease-in-out;
  -moz-transition: height .5s ease-in-out;
  -o-transition: height .5s ease-in-out;
}
@media screen and (min-width: 961px)
{#pcln-global-header .in-path-authorization-outlet {
    width: 320px;
    max-width: 320px;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 100;
}}

#pcln-global-header .header-redesign.hamburger-section {
  overflow-y: scroll;
  position: relative;
  left: -100vw;
  height: 100vh;
  width: 100vw;
  top: -50px;
}
#pcln-global-header .node-invisible, #pcln-global-header .node-bootstrap-invisible {
  display: none!important;
}
@media (min-width: 768px)
{#pcln-global-header .header-redesign.hamburger-section {
    top: -60px;
}}
#pcln-global-header .header-redesign.hamburger-section .hamburger-section__header {
  background-color: #0068ef;
  height: 50px;
  min-width: 320px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ECEFF2;
}
@media (min-width: 769px)
{#pcln-global-header .header-redesign.hamburger-section .hamburger-section__header {
    display: none;
}}
@media (min-width: 768px)
{#pcln-global-header .header-redesign.hamburger-section .hamburger-section__header {
    height: 60px;
}}
#pcln-global-header .header-redesign.hamburger-section .navbar-hamburger {
  background: none;
  width: 56px;
  margin: 0;
  height: 50px;
  padding: 0;
  border-right: 1px solid transparent;
}
#pcln-global-header .header-redesign.hamburger-section .header-left-icon-container {
  background-color: #e8f2ff!important;
  border: 1px solid #0068ef;
}
#pcln-global-header .header-redesign.hamburger-section .navbar-hamburger img {
  width: 14px;
}
#pcln-global-header .header-redesign.hamburger-section .hamburger-menu-pcln-logo {
  margin-top: 3px;
  padding-left: 8px;
  height: 22px;
}
#pcln-global-header #pcln-okta-widget-placeholder {
  display: none;
  position: fixed;
  top: 0;
  z-index: 13000;
  width: 100%;
  height: 100%;
  padding-top: 3.5%;
  min-width: 320px;
  opacity: 0;
  overflow-y: scroll;
  -webkit-transition: opacity .7s cubic-bezier(.52,.01,.46,1)!important;
  -moz-transition: opacity .7s cubic-bezier(.52,.01,.46,1)!important;
  -o-transition: opacity .7s cubic-bezier(.52,.01,.46,1)!important;
  visibility: visible!important;
}

#pcln-global-header .pcln-okta-widget-outer {
  position: relative;
  z-index: 6777271;
  font-family: Arial Regular,sans-serif;
  font-size: 14px;
}
@media (min-width: 910px)
{#pcln-global-header .pcln-okta-widget-outer {
    max-width: 645px;
    height: auto;
    margin: 10px auto;
    position: relative;
}}
#pcln-global-header .pcln-okta-widget-inner {
  margin: 0 auto;
  position: relative;
  height: auto;
  display: block;
  min-height: 500px;
  max-width: 645px;
  z-index: 6777272;
  background: #fff;
}
@media (min-width: 910px)
{#pcln-global-header .pcln-okta-widget-inner {
    display: flex;
    justify-content: flex-end;
}}
#pcln-global-header #pcln-okta-widget-close {
  z-index: 2;
  position: absolute;
  top: 4px;
  right: 2px;
  margin: 0;
  padding: 10px;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  cursor: pointer;
    background-color: #fff;
    color: #666;
  }
  @media (min-width: 910px)
  {#pcln-global-header #pcln-okta-widget-close {
      background-color: #e8f2ff;
  }}
  #pcln-global-header #pcln-okta-widget-target {
    position: relative;
    background: #fff;
    height: 100%;
  }
  @media (min-width: 910px)
  {#pcln-global-header #pcln-okta-widget-target {
      display: inline-block;
      width: 50%;
      padding: 0;
      margin: 0;
  }
  }
  @media (min-width: 910px)
  {#pcln-global-header #pcln-okta-widget-branding {
      background-color: #e8f2ff;
      width: 50%;
      min-height: 655px;
      background-image: url(https://s1.pclncdn.com/design-assets/gwc/sign-in.svg);
      background-repeat: no-repeat;
      background-position: center;
  }}
  #global-header {
      height: 50px;
  }
  @media (min-width: 768px)
  {#global-header {
      height: 60px;
  }}
  
  #pcln-global-header .light-header .dropdown-section .btn-arrow:after {
    border-color: #0068ef transparent transparent;
  }
  #pcln-global-header .header-redesign .dropdown-section .btn-arrow:after {
    border-color: hsl(211, 100%, 10%) transparent transparent;
    display: none;
  }
  #pcln-global-header .dropdown-section .btn-arrow:after {
    content: "";
    border: inset 5px;
    border-color: #fff transparent transparent transparent;
    border-top-style: solid;
    position: absolute;
    right: -5px;
    top: 27px;
  }
  @media (min-width: 320px)
  {#pcln-global-header .dropdown-section .btn-arrow:after {
    display: none;
  }}
  @media (min-width: 768px)
  {#pcln-global-header .dropdown-section .btn-arrow:after {
    display: inline-block;
  }}
  @media (min-width: 1024px)
{#pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .btn-arrow:after {
    top: 24px;
}}
@media (min-width: 1024px)
{#pcln-global-header .header-redesign .dropdown-section .btn-arrow:after {
    display: inline-block;
}}
@media (min-width: 1024px)
{#pcln-global-header .dropdown-section .btn-arrow:after {
    right: 0;
}}
#pcln-global-header .dropdown-section .sign-in-group .btn-priceline .anonymous-greeting {
  font-size: 12px;
}

/* #pcln-global-header .light-header .dropdown-section .sign-in-group .btn-priceline .anonymous-greeting .icon-utility-svg {
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Cg fill=%22none%22 fill-rule=%22evenodd%22%3E%3Cpath fill=%22%230068ef%22 fill-rule=%22nonzero%22 d=%22M10.33 2C5.73 2 2 5.73 2 10.33s3.73 8.34 8.33 8.34 8.34-3.74 8.34-8.34S14.93 2 10.33 2zm0 2.5c1.4 0 2.5 1.12 2.5 2.5s-1.1 2.5-2.5 2.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5zm0 11.83c-2.08 0-3.92-1.06-5-2.68.03-1.66 3.34-2.57 5-2.57 1.66 0 4.98.9 5 2.57-1.07 1.62-2.9 2.68-5 2.68z%22/%3E%3Cpath d=%22M0 0h20v20H0%22/%3E%3C/g%3E%3C/svg%3E);
} */
#pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .nav-icon {
    display: inline-block;
}
#pcln-global-header .dropdown-section .sign-in-group .btn-priceline .anonymous-greeting .icon-utility-svg {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0;
    /* background: url(data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2220%22 height=%2220%22 viewBox=%220 0 20 20%22%3E%3Cg fill=%22none%22 fill-rule=%22evenodd%22%3E%3Cpath fill=%22%23ffffff%22 fill-rule=%22nonzero%22 d=%22M10.33 2C5.73 2 2 5.73 2 10.33s3.73 8.34 8.33 8.34 8.34-3.74 8.34-8.34S14.93 2 10.33 2zm0 2.5c1.4 0 2.5 1.12 2.5 2.5s-1.1 2.5-2.5 2.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5zm0 11.83c-2.08 0-3.92-1.06-5-2.68.03-1.66 3.34-2.57 5-2.57 1.66 0 4.98.9 5 2.57-1.07 1.62-2.9 2.68-5 2.68z%22/%3E%3Cpath d=%22M0 0h20v20H0%22/%3E%3C/g%3E%3C/svg%3E) no-repeat; */
}
#pcln-global-header .dropdown-section .btn-priceline i {
    font-size: 15px;
}
@media (min-width: 320px) and (max-width: 767px)
{#pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group #in-path-sign-in-out-click .icon-utility-svg, #pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .sign-in-group #in-path-sign-in-out-click .icon-utility-profile {
    position: relative;
    top: 3px;
    margin-right: 12px;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
}}
#pcln-global-header .dropdown-section .icon-utility-help {
  display: inline-block;
  width: 20px;
  height: 20px;
}
#pcln-global-header .light-header .dropdown-section .icon-utility-help {
  background: url(../../src/Images/icon1.svg);
}
#pcln-global-header .header-redesign .dropdown-section .icon-utility-trips {
  background: url(../../src/Images/lock.svg);
}
#pcln-global-header .light-header .dropdown-section .sign-in-group .btn-priceline .anonymous-greeting .icon-utility-svg {
  background: url(../../src/Images/user.svg);
}

#pcln-global-header .dropdown-section .btn-priceline i {
    font-size: 15px;
}
#pcln-global-header .dropdown-section .icon-utility-trips {
    display: inline-block;
    width: 20px;
    height: 20px;
}
@media (min-width: 1024px)
{#pcln-global-header .light-header .navbar-header .dropdown-section.vip-design .nav-icon {
    display: none;
}}
#pcln-global-header .header-redesign .dropdown-section .btn-arrow:hover:after {
  border-color: #0068ef transparent transparent;
}
#pcln-global-header .header-redesign .nav-section:hover, #pcln-global-header .header-redesign .global-header-nav-product-item:hover, #pcln-global-header .header-redesign .global-header-nav-product-link:hover {
  color: #0068ef!important;
  text-decoration: underline!important;
}
#pcln-global-header .header-redesign .nav-section:hover, #pcln-global-header .header-redesign .global-header-nav-product-item:hover, #pcln-global-header .header-redesign .global-header-nav-product-link:active {
  color: #0068ef!important;
  text-decoration: none!important;
}
#pcln-global-header .header-redesign .dropdown-section .vip-badge:hover {
  border: 1px solid #0068ef;
}
/* Other Components */
.gjRupo {
  margin-bottom: 64px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1280px;
}
@media screen and (min-width: 40em)
{.gjRupo {
    padding-left: 32px;
    padding-right: 32px;
}}
.hvmlrp {
  margin-top: -32px;
  margin-bottom: -32px;
}
.jaGgOB {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
}
.iqMbjt {
  margin-left: auto;
  margin-right: auto;
}
.bcXIyJ {
    gap: 16px;
}
.Ptooe {
    border-radius: 24px;
    background: linear-gradient(rgb(232, 242, 255), rgb(232, 242, 255)) 0px 24px no-repeat;
    box-shadow: rgb(232, 242, 255) 24px 24px 0px 0px, rgb(232, 242, 255) -24px 24px 0px 0px;
    margin-bottom: 24px;
}
.oYJda {
  display: flex;
  flex-wrap: wrap;
}
.llivTk {
  max-width: 1216px;
}

@media screen and (min-width: 40em)
{
  .oYJda {
    flex-wrap: unset;
  }
  .iCdfOp {
      width: 50%;
  }
}
.kdSzFt {
  transition: box-shadow 300ms cubic-bezier(0.5, 0, 0.25, 1) 0s;
}
.hsOoFy {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.hwxHjM {
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
.doJyA-D {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../../src/Images/mask.webp);
  height: 200px;
  border-radius: 16px 16px 0px 0px;
}
.bWBXvu {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../../src/Images/view.webp);
  height: 200px;
  border-radius: 16px 16px 0px 0px;
}
.kLGKll {
  background-position: center top;
  background-size: cover;
}
.kgMHOD {
  margin-bottom: 4px;
  padding: 16px 16px 8px;
}
.iBXpzE {
  color: rgb(79, 111, 143);
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.063rem;
}
.elhLKq {
  color: rgb(0, 16, 35);
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.eqPnni {
  color: rgb(0, 24, 51);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.003rem;
}
.daarIs {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.cnrZBo {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.cPFeQP {
  margin-right: 16px;
}
.fVrJIT {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
}
.kqMasH {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  border-radius: 16px;
  min-height: 72px;
  padding: 8px;
}
.jjRHoq {
  border-radius: 16px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
  border-width: 1px;
}
.dzwvKv {
  border: 0px;
}
.dcGJoN {
  min-height: 160px;
  margin-right: 16px;
  width: 160px;
}
.jcWEqU {
  display: block;
  max-width: 100%;
  height: 100%;
  border-radius: 12px;
}
.evIdBZ {
  max-width: 160px;
  object-fit: cover;
}
.fiYCwu {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}
.feckNt {
  width: 100%;
}
.cSOqYT {
  color: rgb(0, 24, 51);
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.eGwsri {
  margin-top: 4px;
  padding-top: 8px;
  width: 100%;
}
.kmNFHk {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.iyjFLz {
  margin-right: 0px;
  padding-right: 0px;
  width: 100%;
}
@media screen and (min-width: 48em)
{.kmNFHk {
    flex-direction: row;
}
.iyjFLz {
  margin-right: 4px;
  padding-right: 8px;
}
}
.czUyOF {
  margin-right: -40px;
}
.kdWtyj {
  outline: none;
}
.fHqGUr {
  position: relative;
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(0, 24, 51) !important;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  margin: 0px;
  padding: 14px 12px 14px 40px;
  border-color: rgb(192, 202, 213);
  border-radius: 12px;
  font-size: 16px;
}
.leRkyE {
  margin-top: 4px;
  padding-top: 8px;
}
@media screen and (min-width: 40em)
{.fHqGUr {
    font-size: 14px;
}}
@media screen and (min-width: 48em)
{.leRkyE {
    margin-top: 0px;
    padding-top: 0px;
}}
.bKbHxa {
  white-space: nowrap;
}
.lmgKyB {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 12px;
  font-size: 16px;
  padding: 12px 22px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
}
.hkUFKU {
  width: 100%;
}
.eWQxRd {
  min-height: 104px;
  margin-right: 16px;
  width: 104px;
}
.pSaee {
  display: block;
  max-width: 100%;
  height: 100%;
  width: 104px;
  border-radius: 12px;
}
.evIfdX {
  max-width: 104px;
  object-fit: cover;
}
@media screen and (min-width: 64em) and (max-width: 89.99em)
{.jgwuHU {
    display: none;
}}
.Pgbli {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
  cursor: pointer;
}
.hyoFtz {
  background-color: rgb(232, 242, 255);
  color: rgb(0, 24, 51);
  border-radius: 16px;
  height: 72px;
  width: 72px;
  margin-right: 4px;
}
.jHlNZc {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 104, 239);
  width: 32px;
}
.egaGcs {
  outline: none;
}
.dFCDBu {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.gCbQdB {
  margin-left: 8px;
  margin-right: 8px;
}
.krFFQm {
  font-weight: 700;
  width: 300px;
  font-size: 14px;
  line-height: 16px;
}
.ccmNyz {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kLCEhS {
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.006rem;
}
.iJgYGn {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 104, 239);
  width: 20px;
}
.cpzuBV {
  outline: none;
}
.gjRupo {
  margin-bottom: 64px;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1280px;
}
@media screen and (min-width: 40em)
{
.gjRupo {
    padding-left: 32px;
    padding-right: 32px;
}}
.cxiIfM {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.jYjIPn {
  background-color: rgb(244, 246, 248);
  color: rgb(0, 24, 51);
  border-radius: 16px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
}
.fLxUHz {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.BmYQI {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column-reverse;
  -webkit-box-pack: center;
  justify-content: center;
}
@media screen and (min-width: 48em)
{
.BmYQI {
    flex-direction: row-reverse;
}}
.dHoagx {
  overflow: hidden;
}
.cdpMj {
  margin: -8px;
  width: 100%;
}
@media screen and (min-width: 48em)
{
.cdpMj {
    margin-left: -16px;
    margin-right: -16px;
}}
.bSzpSI {
  display: flex;
  flex-wrap: wrap-reverse;
}
@media screen and (min-width: 64em)
{
.bSzpSI {
    flex-wrap: wrap;
}}
.guNkHI {
  width: 100%;
  padding: 8px;
}
@media screen and (min-width: 40em)
{
.guNkHI {
    width: 50%;
}}
@media screen and (min-width: 48em)
{
.guNkHI {
    padding-left: 16px;
    padding-right: 16px;
}}
.eQsLnH {
  height: 100%;
  text-align: left;
}
.dYbpGs {
  color: rgb(0, 24, 51);
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
.hPTigh {
  color: rgb(0, 16, 35);
  font-size: 16px;
  text-align: left;
  line-height: 28px;
  letter-spacing: -0.006rem;
  font-weight: 500;
}
.kqfIgw {
  margin-top: 16px;
}
.OjPuL {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
}
.ljuAJw {
  height: 100%;
}
.bcqRBO {
  display: flex;
}
@media screen and (min-width: 48em)
{
.bcqRBO {
    display: flex;
}}
@media screen and (min-width: 40em)
{
.bcqRBO {
    display: flex;
}}
.eewULi:hover {
  color: rgb(0, 68, 153);
  text-decoration: underline;
}
.dTIYZG {
  display: block;
  max-width: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.eUjGEU {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  display: flex;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-height: 250px;
  min-height: unset;
}
@media screen and (min-width: 40em)
{
.eUjGEU {
    min-height: 400px;
    max-height: 500px;
}}
.NtmuT {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 40em)
{
.NtmuT {
    flex-direction: row;
}}
.jheWmd, .jheWmd > svg {
  width: 100%;
  height: 100%;
}
.jheWmd {
  transform: scaleX(-1);
}
.bFaeAz {
  display: inline-block;
}
.WYJJs {
  width: 100%;
}
@media screen and (min-width: 40em)
{
.WYJJs {
    width: 60%;
}
}
.izsYur {
  min-height: 206px;
}
@media screen and (min-width: 40em)
{.izsYur {
    min-height: 480px;
}}
.bjPSPu {
  overflow: clip;
}
@media screen and (min-width: 40em)
{
.bjPSPu {
    overflow-x: unset;
}}
.fleGre {
  width: 85px;
  height: 85px;
}
@media screen and (min-width: 40em)
{
.fleGre {
    width: 100px;
    height: 100px;
}}
@media screen and (min-width: 64em)
{
.fleGre {
    width: 125px;
    height: 125px;
}}
.lrsvS {
    position: absolute;
    left: 3%;
}

@media screen and (min-width: 40em)
{.lrsvS {
  bottom: 0px;
  left: -10%;
}}
@media screen and (min-width: 64em)
{.lrsvS {
    left: -12%;
}}
.ioVxDK {
  z-index: 3;
}
.bdbgij {
  display: inline-block;
}
.iwztGw, .iwztGw > svg {
  width: 100%;
  height: 100%;
}
.hTpUvt {
  width: 90%;
  height: 175px;
}
@media screen and (min-width: 40em)
{.hTpUvt {
    height: 374px;
}}
@media screen and (min-width: 64em)
{.hTpUvt {
    width: 100%;
}}
.iYRbFM {
  position: absolute;
  bottom: 8%;
  left: 0px;
  right: 0px;
}
@media screen and (min-width: 40em)
{.iYRbFM {
    left: -2%;
    right: -2%;
}}
@media screen and (min-width: 64em)
{.iYRbFM {
    bottom: 12%;
    left: -10%;
    right: -10%;
}}
.csXEeD {
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 40em)
{.csXEeD {
    z-index: 1;
}}
.kjpQAf {
  width: 100%;
  height: 100%;
  border-radius: 9999px 9999px 0px 0px;
}
.isVdPO {
  overflow: hidden;
}
.dKRUzS {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../../src/Images/tours.jpg);
  width: 100%;
  height: 100%;
}
.froqZr {
  z-index: 5;
}
.eEdKXU {
  width: 100%;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 8px;
}
@media screen and (min-width: 40em)
{.eEdKXU {
    width: 40%;
}}
.fwXoUo {
  color: rgb(0, 60, 138);
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
}
@media screen and (min-width: 48em)
{.fwXoUo {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}}
@media screen and (min-width: 64em)
{.fwXoUo {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
}}
.gjNkko {
  color: rgb(79, 111, 143);
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
@media screen and (min-width: 48em)
{.gjNkko {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}}
.fTsEh {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
  margin-top: 8px;
}
.ewbuYT {
  width: 291.04px;
}
@media screen and (min-width: 48em)
{.ewbuYT {
    width: 400px;
}}
@media screen and (min-width: 64em)
{.ewbuYT {
    width: 450px;
}}
.kHlnRS {
  position: absolute;
  top: -65%;
  right: -40%;
}
@media screen and (min-width: 40em)
{.kHlnRS {
    top: -29%;
    right: -18%;
}}
@media screen and (min-width: 48em)
{.kHlnRS {
    top: -42%;
}}
@media screen and (min-width: 64em)
{.kHlnRS {
    top: -52%;
}}
.ePnAMN {
  z-index: 2;
}
/* sign in */
.heCIPL {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  width: 100%;
}
.goTqVw {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
@media screen and (min-width: 48em)
{.goTqVw {
    flex-direction: row;
}}
.kYzkho {
  margin-right: 0px;
}
@media screen and (min-width: 48em)
{.kYzkho {
    margin-right: 8px;
}}
@media screen and (min-width: 48em)
{.enKceH {
    flex: 1 1 0%;
}}
.eGtAPT {
  color: rgb(0, 24, 51);
  font-weight: 700;
  font-size: 20px;
  margin: 0px 0px 4px;
}
.cELivV {
  text-align: center;
}
@media screen and (min-width: 48em)
{.cELivV {
    text-align: left;
}}
.iMWoqA {
  margin: 0px;
}
.hFMhwq {
  color: rgb(79, 111, 143);
  font-size: 16px;
  margin: 0px;
}
.cELivV {
  text-align: center;
}
@media screen and (min-width: 48em)
{.cELivV {
    text-align: left;
}}
.iMWoqA {
  margin: 0px;
}
.bOvDWU {
  margin-top: 16px;
  width: 100%;
}
@media screen and (min-width: 48em)
{.bOvDWU {
    margin-top: 0px;
    width: auto;
}}
.pvirm {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.juUPFK {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.laIosI {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
@media screen and (min-width: 48em)
{.laIosI {
    flex-direction: row;
}}
.dSdBAN {
  width: 296px;
  min-width: 296px;
  margin-bottom: 16px;
  margin-right: 0px;
}
@media screen and (min-width: 48em)
{.dSdBAN {
    width: 200px;
    min-width: 200px;
    margin-bottom: 0px;
    margin-right: 8px;
}}
@media screen and (min-width: 64em)
{.dSdBAN {
    width: 400px;
    min-width: 400px;
}}
.fPsAVt {
  border-radius: 12px;
}
.hJSqeZ {
  position: relative;
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(0, 24, 51);
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  margin: 0px;
  padding: 14px 12px;
  border-color: rgb(192, 202, 213);
  border-radius: 12px;
  font-size: 16px;
}
@media screen and (min-width: 40em)
{.hJSqeZ {
    font-size: 14px;
}}
.jVvduO {
  height: 48px;
}
.kfShAY {
  min-width: 1px;
  width: 100%;
}
@media screen and (min-width: 64em)
{.kfShAY {
    min-width: 148px;
    width: 148px;
}}
.gzPrHe {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 12px;
  font-size: 16px;
  padding: 12px 22px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255) !important;
  width: 100%;
}
.iNgjWR {
  white-space: nowrap;
}
.hVaSbQ {
  white-space: nowrap;

}
/*  Magic and save */
.isqCVK {
  color: rgb(79, 111, 143);
  margin-bottom: 8px;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.063rem;
}
.bkRuHn {
  display: block;
  max-width: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: contain;
}
.eimqwJ {
  aspect-ratio: 16 / 9;
  object-fit: contain;
  display: flex;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-height: 250px;
  min-height: unset;
}
@media screen and (min-width: 40em)
{.eimqwJ {
    min-height: 400px;
    max-height: 500px;
}}
.frsMYR {
  margin: -8px;
}
.knvObg {
  width: 50%;
  padding: 8px;
}
@media screen and (min-width: 64em)
{.knvObg {
    width: 25%;
}}
.jFwuXl {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  box-shadow: none;
  cursor: default;
}
.gBuBCo {
  height: 100%;
}
.jFwuXl {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  box-shadow: none;
  cursor: default;
}
/* .jjRHoq  {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border: none;
  box-shadow: none;
  cursor: default;
} */
.TZuCs {
  background-color: rgb(232, 242, 255);
  color: rgb(0, 24, 51);
  border-radius: 9999px;
  height: 48px;
  width: 48px;
  margin-right: 4px;
}
.gHvJpT {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 104, 239);
  width: 24px;
}
.eHnFep {
  outline: none;
}
.dxpPZm {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.kLCEhS {
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.006rem;
}
.iHVsUp {
  max-width: 728px;
}
.dLWhTa:hover {
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
}
/* image gallery */
.jUVzqw {
  color: rgb(0, 60, 138);
  margin: 0px 0px 32px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.fZIqsM {
  margin: -8px;
}
.kSKTWB {
  padding: 8px;
  width: 100%;
}
@media screen and (min-width: 32em)
{.kSKTWB {
    width: 100%;
}}
@media screen and (min-width: 40em)
{.kSKTWB {
    width: 50%;
}}
@media screen and (min-width: 48em)
{.kSKTWB {
    width: 50%;
}}
@media screen and (min-width: 64em)
{.kSKTWB {
    width: 66.6667%;
}}
.esxpss {
  background-color: rgb(0, 24, 51);
  color: rgb(255, 255, 255);
  height: 301px;
  width: 100%;
  border-radius: 16px;
}
.jSGePS {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}
.kRgeSZ {
  height: 301px;
  position: relative;
}
.bnQmMX {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/newYork.jpg);
  height: 301px;
  border-radius: 16px;
}
.NHEcp  {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/los-angeles.avif);
  height: 301px;
  border-radius: 16px;
}
.cZOIYG  {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/orlando.jpg);
  height: 301px;
  border-radius: 16px;
}
.sykmU {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Hotels/paris.jpeg);
  height: 301px;
  border-radius: 16px;
}
.lncQFE {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/dallas.avif);
  height: 301px;
  border-radius: 16px;
}
.cXDWsA  {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/san-francisco.avif);
  height: 301px;
  border-radius: 16px;
}
.ldSBtq {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/miami.avif);
  height: 301px;
  border-radius: 16px;
}
.gWSbkB {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/jeddah.jpg);
  height: 301px;
  border-radius: 16px;
}
.iKguPp {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/dubai.jpg);
  height: 301px;
  border-radius: 16px;
}
.iwlNAu {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  background-image: url(../Images/Packages/riyadh.jpg);
  height: 301px;
  border-radius: 16px;
}
.kQkKdz {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 0;
  transition: all 0.2s ease-in-out 0s;
}
.kRgeSZ:hover .ImageTile__ImageTileBackgroundImage-sc-j5s7d5-0 {
  opacity: 0.9;
  cursor: auto;
}
.eUlGHJ {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  background-color: rgb(0, 170, 0);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 0.025em;
  padding: 2px 8px;
  margin-bottom: 8px;
  border-radius: 9999px;
}
.eMiQUR {
  font-size: 10px;
  z-index: 1;
}
.jiydOm {
  z-index: 1;
}
.ewXYRu {
  cursor: pointer;
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.eGtkfR {
  cursor: pointer;
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.ewXYRu {
  cursor: pointer;
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.lahrll {
  text-shadow: rgba(0, 0, 0, 0.5) 0px 1px 1px;
  font-size: 20px;
}
.bvFqQO {
  color: rgb(255, 255, 255);
  padding-left: 4px;
  padding-right: 4px;
}
.kkpsLh {
  padding: 8px;
  width: 100%;
}
@media screen and (min-width: 32em)
{.kkpsLh {
    width: 100%;
}}
@media screen and (min-width: 40em)
{.kkpsLh {
    width: 50%;
}}
@media screen and (min-width: 48em)
{.kkpsLh {
    width: 50%;
}}
@media screen and (min-width: 64em)
{.kkpsLh {
    width: 33.3333%;
}}
@media screen and (min-width: 40em) and (max-width: 47.99em)
{.lkjwMz {
    display: none;
}}

.jjRHoq:hover {
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
}
.hVaSbQ:focus {
  outline: rgb(0, 68, 153) solid 0px;
  box-shadow: rgb(0, 68, 153) 0px 0px 0px 2px;
}
.hVaSbQ:hover {
  background-color: rgb(0, 68, 153);
  color: rgb(255, 255, 255);
}
.fTsEh:hover {
  background-color: rgb(0, 68, 153);
  color: white !important;
}
.OjPuL:hover {
  background-color: rgb(0, 68, 153);
  color: white !important;
}
.hkUFKU:hover {
  background-color: rgb(0, 68, 153);
  color: white !important;
}
.kdSzFt:hover {
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
  color: inherit;
  cursor: pointer;
  outline: 0px;
  text-decoration: none;
}
.kdSzFt {
  transition: box-shadow 300ms cubic-bezier(0.5, 0, 0.25, 1) 0s;
}
.kNhhGi:hover {
  background-color: rgb(0, 68, 153);
  color: rgb(255, 255, 255);
}
.kNhhGi:focus {
  outline: rgb(0, 68, 153) solid 0px;
  box-shadow: rgb(0, 68, 153) 0px 0px 0px 2px;
}
.kULWIA:hover {
  transform: scale(1.02);
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
  transition: all 0.2s ease-in-out 0s;
}
.gQifjs > input:hover ~ svg[data-name="empty"] {
  color: rgb(0, 104, 239);
}
/* *******************************
**********************************
           Footer CSS
**********************************
**********************************/
.hqGofM {
    display: none;
  }
  @media screen and (min-width: 40em)
  {.hqGofM {
      display: block;
  }}
  #pcln-global-footer {
    visibility: visible!important;
  }
  #pcln-global-footer .light-footer {
    display: none;
  }
  @media (min-width: 601px)
  {#pcln-global-footer .light-footer {
      display: block;
  }}
  #pcln-global-footer .light-footer .global-header-footer.global-footer {
    font-family: Montserrat,Arial Regular,sans-serif;
  }
  #pcln-global-footer .global-header-footer.global-footer {
    font: 14px/1.4 Arial,sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #pcln-global-footer .global-header-footer .global-footer__outer {
    min-width: 320px;
    padding: 0 5px;
    background-color: #0068ef;
    color: #fff;
  }
  #pcln-global-footer .global-footer--customized.global-header-footer .global-footer__outer {
    min-width: 320px;
    padding: 0;
    background-color: #0068ef;
    color: #fff;
  }
  #pcln-global-footer .light-footer .global-header-footer .global-footer__outer {
    background-color: #f7f7f7!important;
    color: #001832;
  }
  #pcln-global-footer .global-header-footer .global-footer__inner {
    max-width: 1160px;
    padding: 30px 0;
    margin: 0 auto;
  }
  #pcln-global-footer .global-footer--customized.global-header-footer .global-footer__inner {
    max-width: 1280px;
    padding: 30px 0;
    margin: 0 auto;
  }
  #pcln-global-footer .light-footer .global-header-footer .global-footer__inner {
    padding: 48px 0;
  }
  #pcln-global-footer .global-footer__top {
    padding: 0 23px;
    margin: 0 5px;
  }
  #pcln-global-footer .light-footer .global-footer__top {
    padding: 0 22px 40px;
  }
  #pcln-global-footer .global-footer__top-column--last.global-footer__top-column {
    width: auto;
    float: right;
  }
  .small-post-item .post-date {
    color: #ffb606;
    margin-bottom: 3px;
    font-family: var(--para-font);
    font-weight: 400;
  }
  #pcln-global-footer .global-footer__top-column {
      float: left;
      padding: 0;
      width: 24.2%;
  }
  
  #pcln-global-footer .light-footer .global-footer__top-column {
    width: 45%;
    vertical-align: top;
    display: inline-block;
    float: none;
  }
  @media (min-width: 768px)
  {#pcln-global-footer .light-footer .global-footer__top-column {
      width: 24%;
      padding: 0;
  }}
  @media (min-width: 860px)
  {#pcln-global-footer .global-footer__top-column {
    width: 25%;
  }}
  @media screen and (min-width: 960px)
  {#pcln-global-footer .global-footer__top-column {
    width: 26%;
  }}
  @media screen and (min-width: 1024px)
  {#pcln-global-footer .global-footer__top-column {
    width: 26.5%;
  }}
  #pcln-global-footer .global-footer__top-column__headline {
    color: #fff;
    display: block;
    font-size: 16px;
    padding-bottom: 5px;
  }
  #pcln-global-footer .light-footer .global-footer__top-column__headline {
    color: #001832;
    font-size: 14px;
    padding-bottom: 16px;
  }
  #pcln-global-footer .global-footer__top-column__list {
    list-style: none;
    padding: 0 0 20px;
    margin: 0;
    width: 91.38%;
  }
  #pcln-global-footer .global-footer__top-column__item {
    padding: 5px 0;
    margin: 0;
  }
  #pcln-global-footer .light-footer .global-header-footer.global-footer a {
    color: #001832;
  }
  #pcln-global-footer .global-footer__top-column__item--anchor {
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    background: transparent;
  }
  #pcln-global-footer .global-footer__top:after {
    content: "";
    display: table;
    clear: both;
  }
  #pcln-global-footer .light-footer .global-header-footer .global-footer__footnote {
    color: #596b7d;
  }
  #pcln-global-footer .global-footer--customized .global-footer__footnote:not(:first-child) {
    display: block;
  }
  #pcln-global-footer .global-footer--customized .global-footer__footnote {
    padding: 0 23px;
    margin: 0 5px;
    font-size: 12px;
    line-height: 1.3em;
    text-align: center;
  }
  #pcln-global-footer .light-footer .global-header-footer .global-footer__footnote {
    color: #596b7d;
  }
  
  #pcln-global-footer .global-footer--customized .global-footer__footnote:not(:first-child) {
    display: block;
  }
  #pcln-global-footer .global-footer--customized .global-footer__footnote--disclaimer {
    margin-top: 15px;
  }
  #pcln-global-footer .global-footer--customized .global-footer__footnote {
    padding: 0 23px;
    margin: 0 5px;
    font-size: 12px;
    line-height: 1.3em;
    text-align: center;
  }
  #pcln-global-footer .global-footer--customized .global-footer__pcln-group-logos {
    background: #001832;
    text-align: center;
  }
  #pcln-global-footer .global-footer--customized .global-footer__pcln-group-logos__inner {
    padding: 40px 0 20px;
    margin: 0 auto;
  }
  #pcln-global-footer .global-footer--customized .global-footer__pcln-group-logos__headline {
    margin: 0 20px 25px;
    font-size: 14px;
    color: #fff;
  }
  #pcln-global-footer .global-footer--customized .global-footer__pcln-group-logos__list {
    padding: 0;
  }
  #pcln-global-footer .global-footer--customized .global-footer__pcln-group-logos__item--priceline-svg {
    height: 22px;
    width: 89px;
  }
  #pcln-global-footer .global-footer--customized .global-footer__pcln-group-logos__item__shared {
    margin: 0 10px 20px;
    display: inline-block;
  }
  /* flight search */
  .dUQmGt {
    padding: 16px;
}
.dgSpnW {
  margin-bottom: 8px;
}
.kltLjW {
  margin-right: 16px;
}
.jAHCNY {
  cursor: pointer;
}
.fRRaLj {
  display: block;
  width: 100%;
  margin: 0px;
  z-index: 1;
  color: rgb(79, 111, 143);
  white-space: nowrap;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.2px;
}
@media screen and (min-width: 32em)
{.fRRaLj {
    font-size: 12px;
}}
.lnsQhS {
  display: inline-block;
  color: rgb(79, 111, 143);
  cursor: pointer;
}
/* .iOWFYt {
  appearance: none;
  opacity: 0;
  position: absolute;
  z-index: 0;
} */
input[type="radio" i] {
  height: 20px;
  width: 20px;
  border-width: 2px;
}
.hBwtjZ {
  flex: 0 0 auto;
  line-height: 1;
  width: 28px;
}
.jMVaTD {
  outline: none;
}
.inilkL {
  vertical-align: middle;
}
.iOWFYt ~ svg {
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 2px;
}
.iOWFYt:checked ~ svg {
  color: rgb(0, 104, 239) !important;
}
.VtYfX:focus{
  outline: 0px;
  border-color: rgb(0, 104, 239);
  box-shadow: rgb(0, 104, 239) 0px 0px 0px 2px;
}
/* .css-b62m3t-container{
 margin-left: 40px;
 width: 100%;
 height: 56px;
} */
/* .css-13cymwt-control{
  height: 56px;
} */
.react-datepicker-wrapper input{
  border: 0;
}
.fRRaLj {
  display: block;
  width: 100%;
  margin: 0px;
  z-index: 1;
  color: rgb(79, 111, 143);
  white-space: nowrap;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.2px;
}
@media screen and (min-width: 32em)
{.fRRaLj {
    font-size: 12px;
}}
.ehUrZJ {
  display: inline-block;
  color: rgb(79, 111, 143);
  cursor: pointer;
}
.csGAWq {
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 8px;
}
.koPElt {
  display: flex;
  flex-wrap: wrap;
}
.fPtMyk {
  width: 100%;
  padding: 0px 8px;
}
@media screen and (min-width: 40em)
{.fPtMyk {
    width: 50%;
    padding-top: 8px;
    padding-bottom: 8px;
}}
@media screen and (min-width: 64em)
{.fPtMyk {
    width: 50%;
}}
.bXyETw {
  width: 100%;
}
.hhIGyZ {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  padding: 0px;
  margin-top: 0px;
  border-radius: 24px;
}
@media screen and (min-width: 40em)
{.hhIGyZ {
    border-radius: 12px;
}}
.kFfTeO {
  width: 100%;
  padding-bottom: 16px;
}
@media screen and (min-width: 40em)
{.kFfTeO {
    padding-bottom: 0px;
}}
.WjkcY {
  border-radius: 12px;
}
.ezPCvJ {
  display: block;
  margin: 0px 0px -20px 40px;
  z-index: 1;
  color: rgb(79, 111, 143);
  padding-bottom: 0px;
  width: auto;
  font-size: 10px;
  padding-top: 6px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.2px;
}
.danbLM {
  margin-right: -40px;
}
.VtYfX {
  position: relative;
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(0, 24, 51);
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  margin: 0px;
  padding: 14px 12px 14px 40px;
  border-color: rgb(192, 202, 213);
  border-radius: 12px;
  font-size: 16px;
}
@media screen and (min-width: 40em)
{.VtYfX {
    font-size: 14px;
}}
.kRXxeM {
  width: 100%;
  padding: 0px 8px;
}
@media screen and (min-width: 48em)
{.kRXxeM {
    padding-top: 8px;
    padding-bottom: 8px;
}}
@media screen and (min-width: 64em)
{.kRXxeM {
    width: 33.3333%;
}}
.eplQeC {
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
  justify-content: stretch;
}
@media screen and (min-width: 32em)
{.hhIGyZ {
}}
.gCTxcz {
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  border-radius: 12px;
  font-size: 14px;
  appearance: none;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: rgb(0, 24, 51);
  display: block;
  font-family: inherit;
  font-weight: normal;
  line-height: normal;
  text-align: left;
  border-color: rgb(192, 202, 213);
  margin: 0px;
  width: 100%;
  padding: 8px 20px 8px 16px;
}
.hyaGGX[role="combobox"] {
  position: relative;
  height: 56px;
  padding-left: 8px;
  padding-right: 8px;
}
.EZYWO {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
}
.iqzbxV {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 104, 239);
  margin-right: 8px;
  width: 24px;
}
.lebwMa {
  color: rgb(79, 111, 143);
  width: 100%;
  font-size: 16px;
}
@media screen and (min-width: 48em)
{.lebwMa {
    font-size: 14px;
}}
.bLHgBL {
  width: 100%;
  padding: 0px 8px;
}
@media screen and (min-width: 40em)
{.bLHgBL {
    width: 50%;
    padding-top: 8px;
    padding-bottom: 8px;
}}
@media screen and (min-width: 64em)
{.bLHgBL {
    width: 33.3333%;
}}
.drMhZA {
  max-width: 768px;
}
.eibkaw {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.eyMQxx {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  border-radius: 24px;
  width: 100%;
}
@media screen and (min-width: 32em)
{.eyMQxx {
}}
@media screen and (min-width: 40em)
{.eyMQxx {
    border-radius: 12px;
}}
.iOWVqH {
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  border-radius: 12px;
  font-size: 14px;
  appearance: none;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: rgb(0, 24, 51);
  display: block;
  font-family: inherit;
  font-weight: normal;
  line-height: normal;
  margin: 0px;
  text-align: left;
  border-color: rgb(192, 202, 213);
  width: 100%;
  padding: 8px 20px 8px 16px;
}
.fyECMh[aria-controls="traveler-selection-control-panel"] {
  position: relative;
  height: 56px;
  padding-left: 8px;
  padding-right: 8px;
}
.jXiFwQ {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
}
@media screen and (min-width: 40em)
{.jXiFwQ {
    width: 50%;
    margin-top: 0px;
}}
@media screen and (min-width: 64em)
{.jXiFwQ {
    width: 33.3333%;
}}
.bhbsQA {
  display: block;
  width: 100%;
  margin: 0px 0px -20px 12px;
  z-index: 1;
  color: rgb(79, 111, 143);
  font-size: 10px;
  padding-top: 6px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.2px;
}
.Ijua {
  appearance: none;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  display: block;
  font-family: inherit;
  width: 100%;
  padding: 14px 32px 14px 12px;
  border-color: rgb(192, 202, 213);
  margin: 0px;
  border-radius: 12px;
  font-size: 16px;
}
@media screen and (min-width: 40em)
{.Ijua {
    font-size: 14px;
}}
.hQyEIt {
  cursor: pointer;
  height: 56px;
}
.eAUkiv {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(79, 111, 143);
  margin-left: -32px;
  width: 24px;
}
.bWdZJj {
  pointer-events: none;
}
.jmCKNm {
  width: 100%;
}
@media screen and (min-width: 40em)
{.jmCKNm {
    width: 50%;
}}
.fNHOAf {
  width: 0%;
  padding: 8px;
}
@media screen and (min-width: 40em)
{.fNHOAf {
    width: 100%;
}}
.fYFENW {
  height: 56px;
}
.ecPXvd {
  background-color: rgb(208, 241, 172);
  color: rgb(0, 24, 51);
  padding: 4px;
  border-radius: 12px;
  height: 100%;
}
.AlgmB {
  white-space: nowrap;
}
.jNumaf {
  color: rgb(1, 112, 0);
  font-weight: 700;
  padding-right: 4px;
  font-size: 12px;
  margin: auto auto auto 0px;
}
@media screen and (min-width: 32em)
{.jNumaf {
    padding-right: 8px;
}}
@media screen and (min-width: 64em)
{.jNumaf {
    font-size: 14px;
}}
.hovrrF {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
}
.bTJBky {
  margin-top: 2px;
  padding-right: 4px;
}
@media screen and (min-width: 32em)
{.bTJBky {
    padding-right: 8px;
}}
.iltVqX {
  display: block;
  width: 100%;
  margin: 0px;
  z-index: 1;
  color: rgb(0, 24, 51);
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: 0.2px;
}
.hxFRtH {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
  padding: 2px;
  cursor: pointer;
  background-color: inherit;
  color: rgb(94, 104, 114);
}
.iIhBdY {
  appearance: none;
  opacity: 0;
  position: absolute;
  z-index: 0;
}
.fQHOkP {
  outline: none;
}
.hxFRtH svg {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 2px;
}
.hxFRtH svg[data-name="checked"] {
  display: none;
}
.hxFRtH svg[data-name="indeterminate"] {
  display: none;
}
.hxFRtH > input:not([data-indeterminate="true"]) ~ svg[data-name="empty"] {
}
.hxFRtH > input:checked:not([data-indeterminate="true"]) ~ svg[data-name="checked"] {
  display: inline-block;
  color: rgb(0, 104, 239);
}
.hxFRtH > input:checked:not([data-indeterminate="true"]):hover ~ svg[data-name="checked"] {
  color: rgb(0, 68, 153);
}
.hxFRtH > input:checked:not([data-indeterminate="true"]) ~ svg[data-name="empty"] {
  display: none;
}
.hxFRtH > input:hover ~ svg[data-name="empty"] {
  color: rgb(0, 104, 239);
}
.RoOOB {
  width: 100%;
  padding: 8px 8px 0px;
}
@media screen and (min-width: 40em)
{.RoOOB {
    width: 50%;
}}
@media screen and (min-width: 64em)
{.RoOOB {
    width: 50%;
}}
.hwEbXr {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 12px;
  font-size: 16px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
  width: 100%;
  padding: 0px 4px;
}
.jFIluh {
  text-align: center;
  margin-top: 8px;
  font-size: 12px;
}
.hnrfup {
  width: 100%;
  padding: 8px 8px 0px;
}
@media screen and (min-width: 40em)
{.hnrfup {
    width: 50%;
}}
@media screen and (min-width: 64em)
{.hnrfup {
    width: 50%;
}}
.iOWFYt:focus {
  box-shadow: none;
}
.iOWFYt ~ svg {
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 2px;
}
/* Hotel Listing */
.hpuREe {
  position: sticky;
  top: 0px;
  z-index: 110;
  transition: transform 0.3s ease 0s, box-shadow 0.3s ease 0s;
}
.kuQtDu {
  all: unset;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  border-bottom: 1px solid rgb(192, 202, 213);
}
.gVYMMq {
  margin: auto;
  height: 25px;
}
.kcalFF {
  position: relative;
}
.ljjAAP {
  margin: auto 8px;
  transition: transform 300ms cubic-bezier(0.5, 0, 0.25, 1) 0s;
  border-radius: 9999px;
  color: rgb(0, 104, 239);
}
.edRKIj {
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.bzqoba {
  margin-right: 4px;
  width: 100%;
}
.llNdfK {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.Gzjpn {
  position: relative;
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(0, 24, 51);
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  margin: 0px 15px 0px 0px;
  /* padding: 14px 12px 14px 40px; */
  border-color: rgb(192, 202, 213);
  font-size: 16px;
  border-radius: 12px !important;
}
.HWMyp {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
  padding: 12px 15px;
}
.gCLia-d {
  margin: 12px 0px;
}
.Gzjpn .css-13cymwt-control{
    position: relative;
    appearance: none;
    /* display: block; */
    width: 100%;
    font-family: inherit;
    color: rgb(0, 24, 51);
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    /* margin: 0px 4px 0px 0px; */
    /* padding: 14px 12px 14px 40px; */
    border-color: rgb(192, 202, 213);
    font-size: 16px;
    border-radius: 12px;
}
.kuQtDu:hover {
  cursor: pointer;
  background-color: rgb(237, 240, 243);
  border-radius: 12px;
  margin-bottom: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px 
}
.ipmBhE {
  cursor: pointer;
  border-radius: 16px;
}
.kcalFF {
  position: relative;
}
.fRHKIm {
  padding-right: 8px;
  width: 65%;
}
.gHDiTU {
  padding-top: 1px;
}
.ijknVs {
  color: rgb(0, 170, 0);
  text-align: center !important;
  font-size: 16px;
  font-weight: 700;
}
.HkSpC {
  color: rgb(79, 111, 143);
  font-size: 12px;
  text-align: left;
}
.csvZTM {
  cursor: default;
  text-decoration: none;
}
@media screen and (min-width: 40em)
{.hZjqDq:hover {
    box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.16) 0px 2px 8px 0px, rgba(0, 0, 0, 0.16) 0px 10px 8px -5px, rgba(0, 0, 0, 0.16) 0px 12px 32px -2px;
}}
@media screen and (min-width: 48em)
{.casfcL {
    border-left: 1px solid rgb(192, 202, 213);
    border-top: 0px;
  }
  .HkSpC {
    text-align: center;
}
  .fRHKIm {
      padding-right: 0px;
      width: auto;
  }
  .iysiIk {
    flex-wrap: nowrap;
    -webkit-box-align: center;
    align-items: center;
}
.gHDiTU {
  padding-top: 4px;
}
.ijknVs {
  font-size: 20px;
}
}
.kOMYfA {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 7px 12px;
  background-color: rgb(0, 170, 0);
  color: rgb(255, 255, 255);
  width: 100%;
}
.iysiIk {
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
}
.ggTnOm {
  display: flex;
  flex-direction: column;
}
.iysiIk {
  flex-wrap: nowrap;
  -webkit-box-align: center;
  align-items: center;
}
.hIzCYw {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: center;
}
.fUSPHi {
  height: auto;
}
@media screen and (min-width: 48em)
{.hIzCYw {
    flex-direction: column;
}
.fUSPHi {
    height: 100%;
}
.fIdRda {
  min-height: 240px;
}
}
.dgoPHI {
  position: relative;
}
.gLnKRh {
  height: 100%;
}
.eMuljS {
  width: 100%;
  height: 100%;
}
.cCaSNq {
  position: absolute;
}
.cPqfpR {
  width: 100%;
}
@media screen and (min-width: 40em)
{.cPqfpR {
    right: 0px;
    width: 27px;
}}
.djwVDp {
  padding-top: 16px;
  width: 100%;
}
.drkNgV {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  -webkit-font-smoothing: inherit;
  font-weight: 500;
  color: rgb(0, 104, 239);
  line-height: 1.4;
  vertical-align: inherit;
  background-color: transparent;
  padding: 0px;
}
.hItZFh {
  top: 0px;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  z-index: 10;
  right: auto;
  left: 0px;
  padding: 8px 4px 4px 8px;
}
@media screen and (min-width: 40em)
{.hItZFh {
    left: auto;
    top: 0px;
    right: 0px;
    height: 55px;
    width: 55px;
    padding-top: 12px;
    padding-left: 12px;
    z-index: 10;
}}
.kAXPUW {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.jEqlGx {
  cursor: pointer;
}
.jEqlGx .heart-svg {
  width: 20px;
  position: relative;
  z-index: 10;
}
.jEqlGx .heart {
  fill: rgba(0, 24, 51, 0.5);
  stroke-width: 50px;
  stroke: rgb(255, 255, 255);
  stroke-linecap: round;
  transition: all 0.25s ease-in-out 0s;
}
.jEqlGx .heart-burst, .jEqlGx .heart-burst::after {
  display: block;
  width: 5px;
  height: 5px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  box-shadow: rgb(255, 160, 196) 0px -26px 0px, rgb(255, 160, 196) 0px 26px 0px, rgb(255, 160, 196) -26px 0px 0px, rgb(255, 160, 196) 26px 0px 0px;
}
.jEqlGx .heart-burst::after {
  content: "";
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
}
.jEqlGx .heart-burst {
  top: 30px;
  left: 32px;
}
.gDxjVM {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  width: 100%;
}
.eaVPxX {
  position: relative;
  cursor: pointer;
  min-height: 153px;
}
@media screen and (min-width: 40em)
{.eaVPxX {
    min-height: 264px;
}}
.kwHIxs {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
.juFVep {
  background-color: rgb(0, 24, 51);
  width: 100%;
}
.dgoPHI {
  position: relative;
}
.erKYis {
  height: 100%;
  overflow: hidden;
}
.dpgIZ {
  position: absolute;
  inset: 0px;
  z-index: 0;
}
.hLLYkz {
  padding: 0px;
}
.eNvHXq {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-delay: initial;
  white-space: nowrap;
  transform: translate(0%, 0px);
}
.dwDXYb {
  display: inline;
}
.hLmSyt {
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}
.iDmCsd * {
  box-sizing: border-box;
}
.ebgvjd {
  display: block;
  max-width: 100%;
}
.fhxWfg {
  display: inline;
  max-height: 100%;
  min-width: auto;
  min-height: 100px;
  vertical-align: middle;
  z-index: 110;
  position: relative;
  transition: opacity 0.3s ease 0s;
  user-select: none;
}
.gqbmxJ {
  display: inline;
  width: 100%;
  z-index: 0;
  object-fit: cover;
  height: 100%;
}
.brHOWu {
  display: flex;
}
.dRiUBG {
  position: absolute;
  -webkit-box-pack: center;
  justify-content: center;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 8px;
}
.gzvUFf {
  max-width: 90px;
  overflow-x: hidden;
}
.ipCoSW {
  display: flex;
  flex-direction: row;
}
.mDstq {
  transform: translate(0px, 0px);
  transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s;
  direction: ltr;
  will-change: transform;
}
.gffuWP {
  height: 18px;
  width: 18px;
  cursor: pointer;
  border: 0px;
  background: none;
  padding: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
}
.gfMkOK {
  cursor: pointer;
  height: 8px;
  width: 8px;
  border: none;
  padding: 0px;
  background: none;
}
.cefYII {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  width: 8px;
  border-radius: 50%;
}
.hXamFu {
  border-radius: 50%;
  border-width: 0px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
}
.dlexSF {
  height: 8px;
  background-color: rgb(255, 255, 255);
}
.ggdXfq {
  cursor: pointer;
  height: 6px;
  width: 6px;
  border: none;
  padding: 0px;
  background: none;
}
.gRAOWx {
  height: 6px;
  background-color: rgb(192, 202, 213);
}
.kFkZXz {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-delay: initial;
  white-space: nowrap;
  transform: translate(-400%, 0px);
}
.kOMYfA:focus {
  outline: rgb(0, 102, 0) solid 0px;
  box-shadow: rgb(0, 102, 0) 0px 0px 0px 2px;
}
.kOMYfA:hover {
  background-color: rgb(0, 102, 0);
}
.cHWgzb {
  z-index: 105;
  position: relative;
  width: auto;
}
.juJooJ {
  padding-right: 16px;
  float: left;
  margin-top: 12px;
}
.gMoPDK {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 2px;
}
.giDoGG {
  border-color: rgb(192, 202, 213);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
}
.crjFKm {
  border: 0px;
  margin: 0px auto;
}
@media (min-width: 720px)
{.crjFKm {
}}
.cIFUSm {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.eDEWbr {
  cursor: pointer;
  text-decoration: none;
  color: rgb(0, 104, 239);
}
.fFvqRs {
  display: block;
  color: inherit;
  text-decoration: none;
}
.dMZHbl {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.dMZHbl.dMZHbl {
  text-decoration: none;
}
.egJvso {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 104, 239);
  width: 16px;
  margin-right: 4px;
}
.dvOXuG {
  outline: none;
}
.iyYUkm {
  color: rgb(0, 104, 239);
  font-size: 14px;
  font-weight: 700;
}
.gQSzOC {
  margin: 0px auto;
  width: 100%;
}
@media (min-width: 1024px)
{.gQSzOC {
    width: 1250px;
    padding-left: 25px;
    padding-right: 25px;
}}
/* @media screen and (min-width: 64em)
{.gQSzOC {
    padding-top: 10px;
    padding-bottom: 10px;
}} */
.biEWAU {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  width: 100%;
  border-bottom: 1px solid rgb(192, 202, 213);
}
.dFjWGa {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  /* background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255); */
  margin: 1px 1px 0px;
  padding: 16px;
}
.dxUoaI {
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  background-color: rgb(255, 255, 255);
}
.fJfOGH {
  color: rgb(0, 104, 239) !important;
  font-size: 12px;
  font-weight: 500;
}
@media screen and (min-width: 64em)
{.fJfOGH {
    font-size: 14px;
}}
.ckrUgW {
 opacity: 0;
}
ul li a.current .ckrUgW {
  position: absolute;
  left: 0px;
  bottom: 0px;
  opacity: 1;
}
.ixfzHX {
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);
  min-width: 40px;
  height: 3px;
}
@media screen and (min-width: 64em)
{.ixfzHX {
    width: calc(100% - 48px);
}}
.dPpUjs {
  border-radius: 6px 6px 0px 0px;
  transition: transform 500ms ease 0s;
  transform: translateX(0px);
  opacity: 0;
}
.dPpUjs.tab--right-enter-done {
  transform: translateX(0px);
  opacity: 1;
}
.dxUoaI:hover, .dxUoaI:active {
  background-color: rgb(255, 255, 255);
}
.VsJEb {
  border-radius: 6px 6px 0px 0px;
  transition: transform 500ms ease 0s;
  transform: translateX(-250%);
  opacity: 0;
}
.dFjWGa:hover {
  background-color: rgb(237, 240, 243);
  color: rgb(10, 79, 163) !important;
  /* border: 1px solid #085397; */
}
.fkfxJA:hover, .fkfxJA:active {
  background-color: rgb(237, 240, 243);
}
/* .fkfxJA:focus {
  outline: rgb(0, 104, 239) solid 0.5px;
}
.dFjWGa:focus {
  outline: rgb(0, 68, 153) solid 1px;
  box-shadow: rgb(0, 68, 153) 0px 0px 0px 2px;
} */
/* .dFjWGa:active {
  outline: rgb(0, 68, 153) solid 2px !important;
  color: rgb(0, 68, 153);
} */
.cMhMXY {
  margin-left: 16px;
  margin-right: 16px;
}
@media screen and (min-width: 48em)
{.dliJJP {
  max-width: 768px;
}}
@media screen and (min-width: 64em)
{.dliJJP {
  max-width: 960px;
}}
.dliJJP {
  max-width: 100%;
}
@media screen and (min-width: 32em)
{.cMhMXY {
    margin-left: 32px;
    margin-right: 32px;
}}
.ktTcSV {
  margin-bottom: 0px;
  margin-top: 16px;
}
@media screen and (min-width: 32em)
{.ktTcSV {
    margin-bottom: 24px;
}}
.fFOfCi {
  width: 100%;
  flex-direction: row;
}
.eGeDlK {
  color: rgb(0, 24, 51);
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 0px;
}
@media screen and (min-width: 48em)
{.eGeDlK {
    font-size: 24px;
}}
.bjQiHv {
  display: flex;
  align-items: flex-start;
}
.gnNNl {
  color: rgb(79, 111, 143);
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bZYGh {
  color: rgb(79, 111, 143);
  font-size: 12px;
  font-weight: 700;
}
@media screen and (min-width: 48em)
{.bZYGh {
    font-size: 14px;
}}
.khFTaF {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  -webkit-font-smoothing: inherit;
  font-weight: 500;
  color: rgb(0, 104, 239);
  line-height: 1.4;
  vertical-align: inherit;
  background-color: transparent;
  margin-top: 2px;
  padding: 0px;
}
.iuEGkU {
  border: none;
  background-color: rgb(255, 255, 255);
  font-size: 12px;
}
.duGimm {
  margin-left: 4px;
}
.klMbWH {
  font-size: 12px;
  font-weight: 700;
  margin-left: 5px;
}
.hZcIUX {
  margin-left: 32px;
}
.jrYywt {
  display: none;
  text-align: right;
}
@media screen and (min-width: 48em)
{.jrYywt {
    width: 22%;
    display: block;
}}
.cCKpKv {
  text-align: right;
}
.rLAaP {
  line-height: 40px;
}
.ctfuwv {
  color: rgb(0, 68, 153);
  font-weight: 700;
  font-size: 40px;
}
.hYllkW {
  font-size: 20px;
  line-height: 1;
  vertical-align: baseline;
  bottom: 1.2ex;
  position: relative;
  font-weight: bold;
}

.gIaSZe {
  color: rgb(79, 111, 143);
  font-size: 14px;
}
.cqBiqL {
  margin-bottom: 12px;
  margin-top: 0px;
  white-space: nowrap;
}
.lfvHNo {
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.bMBaBy {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(0, 170, 0);
  color: rgb(255, 255, 255);
}
.eGowrA {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.5, 0, 1, 1) 0s;
}
.JMjyN {
  font-weight: 700;
  font-size: 14px;
}
.kAsriY {
  white-space: nowrap;
}
.dliJJP .brHOWu {
  display: flex;
  justify-content: space-between;
}
.kwmkzy {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin-left: 0px;
  margin-right: 0px;
  opacity: 1;
}
.iZSTjk {
  width: 100%;
  margin-bottom: 32px;
}
@media screen and (min-width: 48em)
{.kfGON {
    margin-top: 32px;
    padding-right: 32px;
    border-right: 1px solid rgb(192, 202, 213);
    width: 50%;
}}
@media screen and (min-width: 64em)
{.kfGON {
    width: 33.3%;
}}
.iDmCsd * {
  box-sizing: border-box;
}
.kjAZcI {
  margin-bottom: 24px;
}
.uXFCt {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
}
@media screen and (min-width: 32em)
{.uXFCt {
    font-size: 16px;
}}
@media screen and (min-width: 40em)
{.uXFCt {
    font-size: 20px;
}}
@media screen and (min-width: 48em)
{.uXFCt {
    font-size: 20px;
}}
.bQNZnh {
  margin-bottom: 16px;
}
.ddScBT {
  background-color: rgb(236, 247, 236);
  color: rgb(0, 24, 51);
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.gayMAW {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.bsUJZA {
  border-radius: 50%;
  flex-basis: 32px;
  flex-shrink: 0;
}
.hOuIer {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 170, 0);
  width: 20px;
  padding-left: 4px;
}
.brbcua {
  margin-left: 8px;
}
.ixiPcI {
  color: rgb(0, 24, 51);
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 12px;
}
.gIaSZe {
  color: rgb(79, 111, 143);
  font-size: 14px;
}
.cWIBJX {
  background-color: rgb(254, 242, 231);
  color: rgb(0, 24, 51);
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
.bsUJZA {
  border-radius: 50%;
  flex-basis: 32px;
  flex-shrink: 0;
}
.zDJak {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(246, 128, 19);
  width: 20px;
}
.brbcua {
  margin-left: 8px;
}
.iVgiTb {
  width: 100%;
  padding-top: 32px;
}
.jzTiZs {
  border-top: 1px solid rgb(192, 202, 213);
}
@media screen and (min-width: 48em)
{.jzTiZs {
    width: 50%;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-top: 0px;
    padding-left: 32px;
    padding-right: 32px;
    border-top: 0px;
}}
@media screen and (min-width: 64em)
{.jzTiZs {
    width: 33.3%;
    border-right: 1px solid rgb(192, 202, 213);
}}
.iDeVNi {
  margin-bottom: 8px;
}
@media screen and (min-width: 40em)
{.iDeVNi {
    margin-bottom: 0px;
}}
.fctwCy {
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
}
.efJUkR {
  background-color: rgb(246, 128, 19);
  color: rgb(255, 255, 255);
  border-radius: 8px;
  margin-right: 8px;
  width: 48px;
  padding: 4px;
}
.clKdln {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
}
.kkwANn {
  height: 28px;
}
.bnszOG {
  font-weight: 700;
  font-size: 20px;
}
.uXFCt {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
}
@media screen and (min-width: 32em)
{.uXFCt {
    font-size: 16px;
}}
@media screen and (min-width: 40em)
{.uXFCt {
    font-size: 20px;
}}
@media screen and (min-width: 48em)
{.uXFCt {
    font-size: 20px;
}}
.bQNZnh {
  margin-bottom: 16px;
}
.dgSpnW {
  margin-bottom: 8px;
}
.dJKBvC {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.bmECKS {
  font-size: 12px;
  font-weight: 700;
}
.hfkzjs {
  font-size: 14px;
  font-weight: 700;
  margin-right: 0px;
  margin-left: 16px;
}
.ga-doeC {
  background-color: rgb(192, 202, 213);
  height: 4px;
  width: 100%;
  border-radius: 8px;
}
.faiveK {
  overflow: hidden;
}
.kBoBML {
  background-color: rgb(246, 128, 19);
  height: 4px;
  width: 88%;
}
.llmARQ {
  height: 4px;
  width: 88%;
  animation: 0.6s ease 0.5s 1 normal forwards running CLE88;
}
.bcOhvn {
  font-size: 14px;
  font-weight: 700;
  margin-right: 0px;
  margin-left: 0px;
}
.jNuJI {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
}
.bgXeoc {
  border-top: 1px solid rgb(192, 202, 213);
}
@media screen and (min-width: 48em)
{.bgXeoc {
    border-top: 1px solid rgb(192, 202, 213);
}}
@media screen and (min-width: 64em)
{.bgXeoc {
    width: 33.3%;
    padding-left: 32px;
    border-top: none;
}}
.izTftl {
  flex-direction: column;
}
.dYqbsU {
  overflow: scroll hidden;
}
@media screen and (min-width: 40em)
{.dYqbsU {
    overflow: auto;
}}
.bgmTtJ {
  flex-wrap: wrap;
}
.gPxwGI {
  width: 25%;
}
@media screen and (min-width: 48em)
{.gPxwGI {
    width: 33%;
}}
@media screen and (min-width: 64em)
{.gPxwGI {
    width: 50%;
}}
.jHESzD {
  margin-bottom: 24px;
  padding-right: 24px;
}
.dxvzAU {
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  flex-flow: row;
  text-align: left;
}
.dxvzAU > .Flex-pcln-design-system__sc-1yrtwh8-0 {
  flex-direction: row;
}
.gWrpZK {
  width: 40px;
  height: 40px;
}
.iDmCsd * {
  box-sizing: border-box;
}
.etIvbM {
  background-color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
}
.jUnDuc {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.kbxCrI {
  position: relative;
  margin-bottom: 4px;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 1px 0px, rgba(0, 0, 0, 0.24) 0px 1px 1px 0px;
}
.jkVzhs {
  background-color: rgb(237, 240, 243);
  width: 36px;
  height: 36px;
}
.jVjUOc {
  border-radius: 100%;
  width: 36px;
  height: 36px;
  overflow: hidden;
}
.fTpksA {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.fXVldm {
  background-color: rgb(0, 104, 239);
}
.fAUQAu {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  right: 0px;
  bottom: 0px;
}
.fAXPed {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(255, 255, 255);
  width: 16px;
}
.jsqwxd {
  font-size: 12px;
  padding-left: 8px;
}
.hVEfxv {
  -webkit-font-smoothing: auto;
  background-color: transparent;
  color: rgb(0, 104, 239);
  font-size: 14px;
  font-weight: 500;
  margin: 0px auto 0px 0px;
  padding: 0px;
}
.jXalzF {
  display: flex;
  flex-flow: wrap;
  -webkit-box-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
@media screen and (min-width: 48em)
{.jXalzF {
    align-items: flex-end;
}}
.ktXtGG {
  outline: none;
  padding-top: 8px;
  border-top: 1px solid rgb(192, 202, 213);
  padding-bottom: 8px;
}
@media screen and (min-width: 40em)
{.ktXtGG {
    padding-top: 16px;
    border-bottom: none;
}}
@media screen and (min-width: 48em)
{.ktXtGG {
    border-top-width: 0px;
}}
.fpmaAk {
  margin-bottom: -8px;
}
@media screen and (min-width: 48em)
{.fpmaAk {
    margin-bottom: 0px;
}}
.jXYUns {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 48em)
{.jXYUns {
    flex-direction: row;
}}
.dsyKEM {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (min-width: 48em)
{.dsyKEM {
    font-size: 24px;
}}
.icvVOM {
  margin: 0px;
}
@media screen and (min-width: 40em)
{.cYdLnE {
    margin-top: 2px;
}}
@media screen and (min-width: 48em)
{.cYdLnE {
    margin-left: 16px;
}}
.kRlXIw {
  margin-top: 8px;
  margin-bottom: 16px;
}
@media screen and (min-width: 48em)
{.kRlXIw {
    margin-left: auto;
    margin-top: 0px;
    margin-bottom: 0px;
}}
.hZMDvQ {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
@media screen and (min-width: 48em)
{.hZMDvQ {
    flex-direction: row;
}}
@media screen and (min-width: 40em)
{.lfyFtr {
    margin-top: 0px;
    margin-bottom: 0px;
}}
.fegQzz {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}
.fegQzz > .Flex-pcln-design-system__sc-1yrtwh8-0 {
  -webkit-box-pack: start;
  justify-content: start;
}
@media screen and (min-width: 64em) and (max-width: 90.99em)
{.bpEwQY {
    display: none;
}}
.dzXNGz {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 64em)
{.dzXNGz {
    -webkit-box-pack: end;
    justify-content: flex-end;
}}
.iCsihk {
  color: rgb(0, 24, 51);
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  margin-bottom: 4px;
}
.bnZUwN {
  padding-left: 4px;
}
.hZbLaw {
  letter-spacing: 0.025em;
}
.jIhEIt {
  color: rgb(0, 24, 51);
  font-size: 12px;
  text-align: center;
  margin-bottom: 0px;
}
@media screen and (min-width: 64em)
{.jIhEIt {
    text-align: right;
}}
.dNxNky {
  cursor: pointer;
  text-decoration: none;
  color: rgb(0, 104, 239);
  margin-left: 4px;
}
.kPJikv {
  text-decoration: underline;
}
.cNXMnW {
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
}
@media screen and (min-width: 32em)
{.cNXMnW {
    margin-left: 32px;
    margin-right: 32px;
}}
.ipbsgI {
  border-top: none;
  padding-top: 0px;
  margin-bottom: 16px;
  gap: 16px;
}
@media screen and (min-width: 40em)
{.ipbsgI {
    margin-bottom: 16px;
    padding-top: 16px;
    border-top: 1px solid rgb(192, 202, 213);
}}
/* *********************************
********* Image container hotel deatil
*********************************** */
.iDmCsd {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4;
  font-weight: 500;
}
.eIydDN {
  display: flex;
}
.kmJYQj {
  flex-flow: wrap;
}
.ciJqJ {
  padding: 0px 0px 16px;
}
@media screen and (min-width: 32em)
{.ciJqJ {
    padding: 0px 0px 16px;
}}
.HbHrI {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
@media screen and (min-width: 32em)
{.HbHrI {
    flex-wrap: wrap;
}}
.KoyBQ {
  margin-top: 16px;
  border-radius: 16px;
  border: 1px solid rgb(192, 202, 213);
}
.ehmsvz {
  margin-bottom: 16px;
}
.kGhvdP {
  width: 100%;
}
.gYFXQx {
  padding-bottom: 48%;
  width: 100%;
}
.dgoPHI {
  position: relative;
}
.bXyETw {
  width: 100%;
}
.fUNrhW {
  position: absolute;
  top: 0px;
  left: 0px;
}
.bhFHkk {
  border: none;
  height: 100%;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
}
.bGJChp {
  border-radius: 16px;
  width: 100%;
}
.cnKDxd {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  height: 320px;
}
.juFVep {
  background-color: rgb(0, 24, 51);
  width: 100%;
}
.iVvcKc {
  height: 100%;
  overflow: hidden;
}
.cNZjdr {
  max-height: 100%;
}
.jKgutU {
  padding: 4px;
  margin: 8px;
}
.fUNrhW {
  position: absolute;
  top: 0px;
  left: 0px;
}
.eVBySI {
  background: rgb(94, 104, 114);
  color: rgb(255, 255, 255);
  font-size: 12px;
  border-radius: 8px;
  z-index: 100;
}
.jrVkbu {
  position: absolute;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  border: 0px;
}
.lkotus {
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 0;
  background-color: rgb(255, 255, 255);
  margin: 8px;
  padding: 4px;
  border-radius: 16px;
  z-index: 100;
}
.jigjHb {
  width: 80px;
  height: 100%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  background: none;
  opacity: 1;
  transition: opacity 0.2s ease-in 0.1s;
}
.hEjgWn {
  position: absolute;
  top: 0%;
  z-index: 1;
}
.iVvcKc:hover .PhotoSwipe__PositionedArrowLeftController-sc-1cx2tme-0 {
  opacity: 1;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.gWcIzk {
  color: rgb(255, 255, 255);
  height: 40px;
  width: 40px;
  box-sizing: border-box;
}
.fkIzmh {
  width: 80px;
  height: 100%;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  background: none;
  opacity: 1;
  transition: opacity 0.2s ease-in 0.1s;
}
.bzJQRg {
  right: 0px;
}
.fkIzmh:hover {
  opacity: 1;
}
.iVvcKc:hover .PhotoSwipe__PositionedArrowLeftController-sc-1cx2tme-0 {
  opacity: 1;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.iVvcKc:hover .PhotoSwipe__PositionedArrowRightController-sc-1cx2tme-1 {
  opacity: 1;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}
.drkNgV:hover {
  color: rgb(0, 68, 153);
  text-decoration: underline;
}
.gWcIzk:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.dwDXYb {
  display: inline;
}
.cnKDxd img {
  height: 100%;
  width: 100%;
}
.bhFHkk img {
  min-height: 80px;
}
.ggdXfq {
  cursor: pointer;
  height: 6px;
  width: 6px;
  border: none;
  padding: 0px;
  background: none;
}
.kSfPMC {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  width: 6px;
  border-radius: 50%;
}
.gRAOWx {
  height: 6px;
  background-color: rgb(192, 202, 213);
}
.fWAacj {
  width: calc(100% - 16px);
  margin-top: -8px;
}
@media screen and (min-width: 32em)
{.fWAacj {
    width: calc(100% - 24px);
}}
.kwfmwn {
  z-index: 1;
}
.eQUyTT {
  border-radius: 16px;
  margin: auto;
}
.gfbFZA {
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
}
.bxCHnZ {
  background-color: rgb(255, 255, 255);
  margin: auto;
  padding: 8px 12px 12px;
  border-radius: 16px;
}
.csAxEc {
  align-self: center;
}
.cebHjO {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (min-width: 32em)
{.cebHjO {
    font-size: 16px;
}}
.yPWXP {
  margin-top: 4px;
}
.eYufAo {
  color: rgb(79, 111, 143);
  font-size: 12px;
}
.czFQKw {
  color: rgb(0, 16, 35);
  font-weight: 700;
  font-size: 12px;
  margin-top: 4px;
}
.jutuuU {
  line-height: 18px;
}
.jzYUts {
  margin-top: 8px;
}
.kcpoDZ {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.iFnCLT {
  width: auto;
  margin-right: 16px;
  margin-bottom: 8px;
}
.ibEGsf {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 170, 0);
}
.dMAmjv {
  font-size: 12px;
}
.gQnuJA {
  background-color: inherit;
}
.bqkgGL {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
}
.hdAeul {
    border-radius: 50%;
}
.ixhlkW {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 170, 0);
  width: 18px;
  margin-right: 8px;
}
.hIuQSD {
  font-weight: 700;
}
.jwDsFY {
  margin-top: 4px;
  font-size: 14px;
}
.zcfXr {
  width: calc(100% - 16px);
}
@media screen and (min-width: 32em)
{.zcfXr {
    width: calc(100% - 24px);
}}
.fihmfN {
  margin: -1px auto auto;
}
.egjWBu {
  margin-top: 0px;
}
.lkCWDC {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.16) 0px 2px 1px -1px, rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
}
.bBYTmp {
  border-radius: 16px;
  border-width: 0px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
}
.jorfE {
  cursor: pointer;
}
.gnbdky {
  display: flex;
  flex-direction: row;
}
.gUaRrO {
  padding: 8px 12px 12px;
}
@media screen and (min-width: 32em)
{.gUaRrO {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
}}
.ctFHh {
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.jBlZcv {
  width: 40%;
}
@media screen and (min-width: 32em)
{.jBlZcv {
    width: 55%;
}}
.cVJMBb {
  flex-shrink: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.flBzdP {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.dejomW {
  margin-top: 8px;
}
.bGQTjl {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  white-space: pre-line;
}
.eYufAo {
  color: rgb(79, 111, 143);
  font-size: 12px;
}
.hIuQSD {
  font-weight: 700;
}
.bCwlin {
  margin-bottom: 4px;
}
.dJIatV {
  color: rgb(0, 16, 35);
}
.dhQkRP {
  font-size: 12px;
}
.kqTRsk {
  width: 130px;
  padding-left: 0px;
  padding-right: 0px;
}
@media screen and (min-width: 32em)
{.kqTRsk {
    width: 45%;
    padding-left: 0px;
    padding-right: 0px;
}}
.bvXQlp {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.gxMehY {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.gmaMFq {
  position: relative;
  white-space: nowrap;
}
.eEgiYO {
  margin-top: 4px;
}
.rLzYt {
  line-height: 24px;
}
.fYqIWV {
  color: rgb(0, 68, 153);
  font-weight: 700;
  font-size: 20px;
}
.dURtmi {
  font-size: 10px;
  line-height: 1;
  vertical-align: baseline;
  bottom: 1.3ex;
  position: relative;
  font-weight: bold;
}
.gya-Dwn {
  color: rgb(79, 111, 143);
  font-size: 12px;
  text-align: right;
}
.fhgHhr {
  width: 100%;
}
.jJxSvm {
  margin-top: 8px;
}
.bkUQzO {
  display: flex;
  align-items: flex-end;
  flex-flow: column wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
.epKcre {
  width: 100px;
  padding-left: 4px;
}
.cesWFB {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(0, 170, 0);
  color: rgb(255, 255, 255);
  width: 100%;
}
.kfOXqK {
  font-size: 12px;
  padding: 7px 12px;
}
.kfOXqK:hover {
  border: #001832;
  padding: 7px 12px;
}
.kfOXqK:active {
  background-color: rgb(12, 102, 12);
  padding: 7px 12px;
}
.jrVkbu {
  position: absolute;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  border: 0px;
}
.bJbPHj {
  display: flex;
  flex-wrap: wrap;
}
.jorfE:hover {
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 4px 0px, rgba(0, 0, 0, 0.12) 0px 6px 4px -4px, rgba(0, 0, 0, 0.16) 0px 8px 16px -1px;
}
.jwNHal {
  color: rgb(0, 170, 0);
  font-size: 12px;
}
.bIgisn {
  color: rgb(0, 170, 0);
  font-size: 14px;
  font-weight: 700;
}
.jsoPfu {
  display: inline-flex;
}
.gmaMFq > div + div {
  margin-left: 8px;
}
.rLAaV {
  line-height: 20px;
}
.iSfVyZ {
  position: relative;
  display: inline-block;
}
.iSfVyZ::before {
  content: "";
  width: 90%;
  position: absolute;
  right: 0px;
  top: 38%;
  border-bottom: 1px solid rgb(79, 111, 143);
  transform: skewY(-10deg);
}
.ghQdEF {
  color: rgb(79, 111, 143);
  font-weight: 500;
  font-size: 14px;
}
.pZWSO {
  font-size: 8px;
  line-height: 1;
  vertical-align: baseline;
  bottom: 1ex;
  position: relative;
  font-weight: normal;
}
.jXcSIt {
  padding-right: 2px;
  bottom: 1ex;
  font-weight: normal;
}
.qOvtC {
  margin-top: 0px;
}
.bYtFKZ {
  color: rgb(0, 170, 0);
  font-weight: 700;
  font-size: 24px;
}
.bQDYAi {
  padding-right: 2px;
  bottom: 1.3ex;
  font-weight: bold;
}
.ichsfB {
  margin-top: 4px;
  width: 100%;
}
.bWSMKb {
  color: rgb(79, 111, 143);
  font-size: 12px;
  text-align: center;
}
.kwmkzy {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin-left: 0px;
  margin-right: 0px;
}
.gtTPFM {
  max-width: 100%;
}
.DUVRK {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
}
@media screen and (min-width: 32em)
{.QsWjo {
    flex-direction: column;
}}
.QsWjo {
  display: flex;
}
@media screen and (min-width: 40em)
{.QsWjo {
    flex-wrap: wrap;
}}
@media screen and (min-width: 64em)
{.QsWjo {
    flex-flow: row;
}}
@media screen and (min-width: 40em)
{.cwMOhW {
    flex: 1 0 40%;
}}
.juJwPl {
  margin-top: 16px;
}
.kZUiTn {
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
}
.jgLiXo {
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  display: block;
}
@media screen and (min-width: 48em)
{.jgLiXo {
    display: block;
}}
@media screen and (min-width: 64em)
{.jgLiXo {
    border-bottom: 0px;
}}
.gJzErg {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 0px;
}
@media screen and (min-width: 32em)
{.gJzErg {
    font-size: 16px;
    margin-bottom: 4px;
}}
@media screen and (min-width: 40em)
{.gJzErg {
    font-size: 16px;
    margin-bottom: 4px;
}}
.eaelwp {
  color: rgb(79, 111, 143);
  line-height: 20px;
  font-size: 14px;
}
.CiomQ {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin-top: 16px;
  margin-left: 0px;
  margin-right: 0px;
   opacity: 1;
}
.kpQSwr {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 0px;
}
@media screen and (min-width: 32em)
{.kpQSwr {
    font-size: 16px;
    margin-bottom: 8px;
}}
@media screen and (min-width: 40em)
{.kpQSwr {
    font-size: 16px;
    margin-bottom: 16px;
}}
.dFUvld {
  font-size: 12px;
}
.dFUvld tr {
  font-size: 12px;
}
.dFUvld tr:nth-child(2n+1) {
  background-color: rgb(244, 246, 248);
}
.dFUvld th {
  display: none;
}
.kxYfiX {
  border-right: 2px solid rgb(255, 255, 255);
}
.dFUvld td {
  margin: 0px;
  padding: 8px;
}
.dFUvld td:first-child {
  width: 30%;
}
@media screen and (min-width: 32em)
{.dFUvld td:first-child {
    width: 24%;
}}
@media screen and (min-width: 40em)
{.dFUvld td:first-child {
    width: 19%;
}}
@media screen and (min-width: 48em)
{.dFUvld td:first-child {
    width: 16%;
}}
@media screen and (min-width: 64em)
{.dFUvld td:first-child {
    width: 14%;
}}
.jrtJHJ {
  font-size: 12px;
  margin-right: 0px;
  text-align: right;
  font-weight: 700;
}
.iFdUYL {
  max-width: 100px;
}
@media screen and (min-width: 32em)
{.iFdUYL {
    max-width: inherit;
}}
.jgsqC {
  margin: 8px;
  width: 80%;
}
.foFPhq {
  margin-bottom: 0px;
}
.fjmVNW {
  display: inline-flex;
}
.bEBSGl {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 24, 51);
  width: 20px;
  margin-right: 8px;
}
.iCfoxR {
  font-size: 14px;
  font-weight: 700;
}
.gXfUIb {
  margin-bottom: -8px;
}
.cLsHZx {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 170, 0);
  width: 20px;
  margin-right: 8px;
}
.fpxnDT {
  color: rgb(0, 24, 51);
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}
.frGNoy {
  font-weight: 700;
}
.gWBVRI {
  margin-top: 0px;
  margin-left: 16px;
}
.eJoEVm {
  padding: 0px;
  margin-top: 0px;
}
.hkMLOd {
  margin: 8px 0px 8px 8px;
  text-indent: 0px;
  color: rgb(79, 111, 143);
}
.dFUvld li {
  list-style: none;
  text-indent: -20px;
}
.dFUvld li::before {
  content: "·";
  margin-right: 16px;
  font-size: 14px;
  vertical-align: baseline;
}
.fqVryd {
  margin-top: 24px;
  margin-bottom: 8px;
}
.fXZPlk {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 0px;
}
@media screen and (min-width: 32em)
{.fXZPlk {
    font-size: 16px;
    margin-bottom: 8px;
}}
@media screen and (min-width: 40em)
{.fXZPlk {
    font-size: 16px;
    margin-bottom: 8px;
}}
.gFoUce {
  color: rgb(79, 111, 143);
  font-size: 14px;
}
.iQWfjd {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 0px;
}
.kwmkzy {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin-left: 0px;
  margin-right: 0px;
  opacity: 1;
}
.dSghoR {
  background-color: rgb(255, 255, 255);
}
.knXGhI {
  margin-top: 4px;
  margin-right: 8px;
}
.iKucya {
  margin-top: -5px;
}
.jxfFFn {
  color: rgb(0, 104, 239);
  width: 36px;
  height: 36px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
  border-radius: 9999px;
  background: rgb(255, 255, 255);
  animation: auto ease 0s 1 normal none running none;
}
.cccCQY {
  flex: 0 0 auto;
  line-height: 1;
  width: 25px;
}
.jziQSv {
  margin-left: 5px;
  margin-top: 4px;
}
.dBMjnT {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}
.gKgdHj {
  line-height: 21px;
}
.dwCBED {
  font-size: 12px;
  line-height: 18px;
}
.gKgdAp {
  line-height: 18px;
}
.eSnpzm {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: black;
}
.cxaTTs {
  display: flex;
  align-items: flex-end;
}
.bzJxON {
  width: 25%;
  margin-right: 32px;
}
.ervxCT {
  margin-bottom: -4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.003rem;
}
@media screen and (min-width: 48em)
{.ervxCT {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: black;
}}
.dVWxdi {
  font-size: 14px;
  font-weight: 700;
  margin: 0px 0px -4px;
}
@media screen and (min-width: 48em)
{.dVWxdi {
    font-size: 16px;
}}
.ePpiEV {
  background-color: rgb(240, 111, 32);
  color: rgb(255, 255, 255);
  height: 20px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
}
@media screen and (min-width: 48em)
{.ePpiEV {
    height: 22px;
}}
.blyaJX {
  display: inline-block;
  line-height: 1.5;
  font-weight: 700;
  border-radius: 8px;
}
.gcQYQw {
  background-color: rgb(237, 240, 243);
  height: 4px;
  width: 100%;
  border-radius: 8px;
}
@media screen and (min-width: 48em)
{.gcQYQw {
    height: 8px;
}
}
.gRBngB {
  background-color: rgb(240, 111, 32);
  height: 4px;
}
@media screen and (min-width: 48em)
{.gRBngB {
    height: 8px;
}}
.esgyzg {
  width: 84%;
  animation: 0.6s ease 0.5s 1 normal forwards running Ove84;
}
.eGMzlJ {
  flex: 1 1 0%;
}
.jjJyTR {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: black;
}
.iEvZTr {
  width: 33.3333%;
}
.kTqdvX {
  margin-left: 32px;
  width: 33.3333%;
}
.ehOnXj {
  margin-bottom: -4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.006rem;
}
@media screen and (min-width: 48em)
{.ehOnXj {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.003rem;
    color: black;
}}
.ksWPfx {
  margin-bottom: -8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.006rem;
}
@media screen and (min-width: 48em)
{.ksWPfx {
    margin-bottom: -4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}}
.fbqiKi {
  width: 86%;
  animation: 0.6s ease 0.5s 1 normal forwards running Sta86;
}
.hbVrgV {
  width: 89%;
  animation: 0.6s ease 0.5s 1 normal forwards running Loc89;
}
.gGHvZQ {
  width: 88%;
  animation: 0.6s ease 0.5s 1 normal forwards running Cle88;
}
.cwEuHu {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin: 32px 0px;
  opacity: 1;
}
@media screen and (min-width: 40em)
{.btBzhj {
    flex-direction: column;
}}
@media screen and (min-width: 64em)
{.btBzhj {
    flex-direction: column;
}}
.cphENy {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 16px;
}
@media screen and (min-width: 40em)
{.homwJr > div:nth-child(1) {
    flex-direction: column;
}}
@media screen and (min-width: 64em)
{.homwJr > div:nth-child(1) {
    flex-direction: row;
}}
.kVfdRt {
  padding-right: 32px;
  padding-bottom: 8px;
}
@media screen and (min-width: 40em)
{.kVfdRt {
}}
@media screen and (min-width: 64em)
{.kVfdRt {
    width: 15%;
}}
.bEbGEP {
  flex-flow: row;
}
.hceaXX {
  background-color: rgb(246, 128, 19);
  color: rgb(255, 255, 255);
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
}
.blyaJX {
  display: inline-block;
  line-height: 1.5;
  font-weight: 700;
  border-radius: 8px;
}
.diaqkO {
  font-size: 20px;
  min-width: fit-content;
  width: 48px;
  text-align: center;
}
.gTgcAr {
  color: rgb(79, 111, 143);
  margin-top: 12px;
  font-size: 12px;
}
.bIMWka {
  white-space: nowrap;
}
.cyBYZx {
  color: rgb(0, 24, 51);
  font-size: 12px;
}
@media screen and (min-width: 40em)
{.lfrjsi {
}}
@media screen and (min-width: 64em)
{.lfrjsi {
    width: 85%;
}}
.gSRZuX {
  border-radius: 12px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
  border-width: 1px;
}
.cfpzyC {
  background-color: rgb(255, 255, 255);
  padding: 12px 16px;
}
.gPuaBj {
  border-radius: 12px;
}
.gPuaBj::after {
  content: " ";
  position: absolute;
}
.iYqLfI {
  background-color: rgb(236, 247, 236);
  color: rgb(0, 24, 51);
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.fTTUvj {
  border-radius: 50%;
  flex-shrink: 0;
}
.bnqrEj {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 170, 0);
  width: 20px;
  margin-left: 6px;
  margin-top: 6px;
}
.cInCXq {
  color: rgb(79, 111, 143);
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
}
.kTfOYx {
  position: relative;
  flex: 1 1 0%;
}
.cqogvA {
  max-height: unset;
  overflow-y: hidden;
}
.dBRFJj {
  color: rgb(0, 24, 51);
  font-size: 14px;
}
.fHBNwk {
  line-height: 1.571em;
}
.kRClNC {
  background-color: rgb(255, 255, 255);
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  margin-top: 8px;
}
.gPuaBj::after {
  content: " ";
  position: absolute;
}
.jAxqKl {
  background-color: rgb(244, 246, 248);
  color: rgb(0, 24, 51);
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.gIXjiC {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(79, 111, 143);
  width: 20px;
  margin-left: 6px;
  margin-top: 6px;
}
.fEdCeI {
  margin-bottom: 16px;
  margin-top: 8px;
}
.gsltyo {
  color: rgb(0, 24, 51);
  font-size: 12px;
}
@media screen and (min-width: 40em)
{.homwJr > div:nth-child(2) {
    flex-direction: column;
}}
@media screen and (min-width: 64em)
{.homwJr > div:nth-child(2) {
    margin-left: 0px;
    margin-top: 24px;
    flex-direction: row;
}}
.hFHtRf {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 700;
  color: black;
}
.kOtbwR {
  margin-bottom: 16px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 4px;
  font-size: 16px;
}
.fLhwda {
  margin-top: 40px;
}
.eoJzYm {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(237, 240, 243);
  color: rgb(0, 104, 239);
  margin-bottom: 16px;
}
.KORie {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.eoJzYm:hover {
  background-color: rgb(213, 220, 228);
  color: rgb(0, 68, 153);
}
.eoJzYm:focus {
  outline: rgb(0, 68, 153) solid 0px;
  box-shadow: rgb(0, 68, 153) 0px 0px 0px 2px;
}
.hrTKQS {
  background-color: rgb(79, 111, 143);
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
.indicater .carousel-indicators{
  opacity: 0;
}
.kmfzg {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
  width: 48px;
  height: 48px;
}
.hKlqBb {
  position: absolute;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 4px 0px, rgba(0, 0, 0, 0.12) 0px 6px 4px -4px, rgba(0, 0, 0, 0.16) 0px 8px 16px -1px;
  background-color: rgb(255, 255, 255);
  height: 48px;
  top: calc(50% - 24px);
  left: 16px;
}
.hKlqBb > .Flex-pcln-design-system__sc-1yrtwh8-0 {
  -webkit-box-pack: start;
  justify-content: start;
}
.hyYIBn {
  position: absolute;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 1px 4px 0px, rgba(0, 0, 0, 0.12) 0px 6px 4px -4px, rgba(0, 0, 0, 0.16) 0px 8px 16px -1px;
  background-color: rgb(255, 255, 255);
  height: 48px;
  top: calc(50% - 24px);
  right: 16px;
}
.hyYIBn > .Flex-pcln-design-system__sc-1yrtwh8-0 {
  -webkit-box-pack: end;
  justify-content: end;
}
.gHvJpT {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 104, 239);
  width: 24px;
}
.modal.fade .modal-dialog {
  border-radius: 30px;
}
.heoKlL {
  margin-top: 4px;
  margin-bottom: 8px;
}
.egUcfp {
  font-size: 24px;
  margin-bottom: 0px;
  padding-top: 4px;
  color: black;
}
.iDmCsd {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4;
  font-weight: 500;
}
.dMzUTD {
  padding-top: 8px;
  padding-bottom: 4px;
}
.dckIEK {
  height: 410px;
  display: flex;
}
.buRICd {
  /* overflow: auto; */
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}
.hTPOWJ {
  display: inline-flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  height: 100%;
  min-width: 100%;
  box-sizing: content-box;
}
.dKVIXv {
  height: 100%;
  display: flex;
  position: relative;
  top: 11px;
  -webkit-box-align: center;
  align-items: center;
}
.muNWO {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  border-radius: 16px;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}
.iwjFUj {
  cursor: pointer;
}
.iwjFUj:hover {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px 0px, rgba(0, 0, 0, 0.24) 0px 4px 16px 0px;
}
.byYMpc {
  min-height: 168px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
.boSRrL {
  overflow: hidden;
}
.jFEgDN {
  min-height: 355px;
}
.hjHzq {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
}
.lnfoQs {
  width: 100%;
}

.iTzpZe {
  position: relative;
  cursor: pointer;
  min-height: 153px;
}
.jKElEr {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 242);
  background-attachment: scroll;
  /* background-image: url(https://mobileimg.pclncdn.com/htlimg/master/540/1/3/54013804/master_54013804?opto=true&crop=261:261,offset-y100&auto=webp); */
  width: 100%;
}
.guvvK {
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
}
.dLgWAY {
  width: 100%;
  padding: 8px;
}
.kwTBvr {
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.GVgVi {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.ctOiCE {
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: 0px;
}
.dlpexW {
  margin-bottom: 8px;
}
.hABLsV {
  font-weight: 700;
  margin: 0px;
}
.cOa-dGf {
  cursor: pointer;
  text-decoration: none;
  color: rgb(0, 16, 35);
}
.gXfgSv {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
.jJGxce {
  margin-bottom: 0px;
}
.jfdNek {
  display: inline-flex;
}
.jlaShT {
  color: rgb(79, 111, 143);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 8px;
  -webkit-box-align: center;
  align-items: center;
}
.dDlVqX {
  overflow: hidden;
  white-space: nowrap;
}
.jgAEOo {
  color: rgb(0, 24, 51);
  display: flex;
}
.hxngiQ {
  color: rgb(0, 104, 239);
  display: flex;
}
.hvUizq {
  color: rgb(79, 111, 143);
  font-weight: 700;
  font-size: 11px;
}
.emqyYq {
  -webkit-font-smoothing: antialiased;
}
.eUIEFT {
  align-self: center;
  flex-shrink: 0;
}
.ecPbyy {
  margin-bottom: 4px;
}
.ibbmoM {
  text-align: center;
}
.cgKvdi {
  overflow: hidden;
}
.idAHBB {
  margin-right: 4px;
}
.bUvHvR {
  flex: 0 0 auto;
  line-height: 1;
  width: 20px;
}
.iJdXHC {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.006rem;
}
.gnvruv {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (min-width: 32em) and (max-width: 99998.99em)
{.jvrnLN {
    display: none;
}}
.TVAJB {
  color: rgb(0, 104, 239);
  font-weight: 700;
  margin-left: 8px;
}
.gFusSs {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.006rem;
}
.hMJHTx {
  margin-bottom: 0px;
}
.rCVFu {
  margin-top: auto;
}
.fejXoU {
  font-size: 12px;
}
.bsMzZY {
  background-color: rgb(246, 128, 19);
  color: rgb(255, 255, 255);
  min-width: 32px;
  height: 20px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
}
.blyaJX {
  display: inline-block;
  line-height: 1.5;
  font-weight: 700;
  border-radius: 8px;
}
.bYmMxZ {
  padding: 0px;
}
.hybSXr {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}
.gVLirO {
  padding-right: 2px;
  width: 100%;
}
.BJEdV {
  margin-left: 0px;
  width: 100%;
}
.dvniSq {
  position: relative;
}
.dPpMDv {
  font-weight: 500;
  font-size: 11px;
}
.emUQqf {
  white-space: initial;
}
.dKNtyI {
  width: 1px;
  height: 16px;
  background-color: rgb(0, 24, 51);
  margin: 0px 8px;
  border: 0px;
}
.bhZOkw {
  letter-spacing: 0.025em;
}
.bDvVvx {
  width: 100%;
  padding: 8px;
  height: auto;
}
.hgDYXC {
  border-top: 1px solid rgb(192, 202, 213);
}
.qcvUI {
  height: auto;
}
.foEYhm {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.nfzvG {
  display: flex;
  flex-flow: column wrap;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
}
.erwWjn {
  padding-right: 8px;
  width: 65%;
}
.gsiNzy {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}
.gdTwDF {
  width: 35%;
}
.hVbGFU {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.jtgGRV {
  color: rgb(0, 68, 153);
  font-weight: 700;
  padding-right: 1px;
  font-size: 12px;
}
.jRHxKY {
  vertical-align: text-top;
}
.exIBst {
  color: rgb(0, 68, 153);
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
}
.jErPHL {
  white-space: nowrap;
}
/* ****************************
********Hotel Checkout ********
*******************************/
.ilAeoW {
  border-width: 0px;
  border-radius: 6px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
}
.hDLmyD {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  border-radius: 16px;
  margin-bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
@media screen and (min-width: 32em)
{.hDLmyD {
    margin-bottom: 16px;
}}
.klVZFh {
  border-radius: 16px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
  border-width: 1px;
}
.kqgbKn {
  margin: 8px 16px 16px;
}
@media screen and (min-width: 48em)
{.kqgbKn {
    margin-left: 0px;
    margin-right: 0px;
}}
.dnXgfb {
  padding: 8px;
}
@media screen and (min-width: 32em)
{.dnXgfb {
    padding: 16px;
}}
.iogQpj {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 12px;
  font-size: 16px;
  padding: 12px 22px;
  background-color: rgb(0, 170, 0);
  color: rgb(255, 255, 255);
  width: 100%;
}
.jXuMzM {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
.klVZFh {
  border-radius: 16px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
  border-width: 1px;
}
.yOFdB {
  margin: 8px 16px 16px;
}
@media screen and (min-width: 48em)
{.yOFdB {
    margin-left: 0px;
    margin-right: 0px;
}}
.kYgxIw {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.hIbIIZ {
  color: rgb(0, 24, 51);
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
}
@media screen and (min-width: 32em)
{.hIbIIZ {
    font-size: 16px;
}}
@media screen and (min-width: 40em)
{.hIbIIZ {
    font-size: 20px;
}}
.eznZRo {
  list-style-type: disc;
  color: rgb(79, 111, 143);
  padding-bottom: 8px;
  padding-left: 16px;
}
.jsPDlj {
  color: rgb(0, 24, 51);
  font-size: 12px;
}
.iQyTkn {
  font-weight: 700;
}
.iiodrr {
  cursor: pointer;
  text-decoration: none;
  color: rgb(0, 104, 239);
}
.fHRQNh {
  position: absolute;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  border: 0px;
}
.cDZpWC {
  padding-right: 4px;
}
.bkTaLI {
  font-weight: 700;
}
.yDRhZ {
  background-color: rgb(244, 246, 248);
  color: rgb(79, 111, 143);
}
.ehgxZg {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.fVCbYR {
  height: 33px;
  font-weight: bold;
}
@media screen and (min-width: 64em) and (max-width: 90.99em)
{.erDZQL {
    display: none;
}}
.cIhMIl {
  padding: 12px 16px;
}
.cnOikk {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ePSwcs {
  flex: 0 0 auto;
  line-height: 1;
  width: 16px;
}
.eHnFep {
  outline: none;
}
.fMsDNd {
  font-size: 12px;
  margin-left: 8px;
}
.bpaeDt {
  width: 620px;
}
.fbPhHB {
  display: flex;
  justify-content: space-around;
}
.fYuirS {
  background-color: rgb(255, 255, 255);
  margin-top: 16px;
}
.eWLJqZ {
  background-color: transparent;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (min-width: 40em)
{.eWLJqZ {
    margin-top: 8px;
    margin-bottom: 8px;
}}
.dVjqBv {
  max-width: 1280px;
}
.kDyrQm {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.fFlby {
  padding-left: 0px;
  padding-right: 0px;
}
@media screen and (min-width: 64em)
{.fFlby {
    padding-left: 128px;
    padding-right: 128px;
}}
.gpuPII {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
}
@media screen and (min-width: 40em)
{.gpuPII {
    padding-left: 128px;
    padding-right: 128px;
}}
.oipPi {
  display: flex;
  align-items: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
}
.eEXOhw {
  height: 40px;
}
.aKcUe {
  margin-left: 25px;
}
.dNxwPQ {
  display: flex;
}
.lhlKWp {
  color: rgb(0, 24, 51);
  font-size: 12px;
}
@media screen and (min-width: 32em)
{.lhlKWp {
    font-size: 12px;
}}
@media screen and (min-width: 40em)
{.lhlKWp {
    font-size: 14px;
}}
.ejmSJE {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.dUnMKc {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
}
.ejGFTW {
  width: 25px;
  height: 25px;
  border-radius: 75px;
  border: 1px solid rgb(0, 104, 239);
  background-color: rgb(0, 104, 239);
  z-index: 1;
}
.giZvOy {
  font-size: 12px;
}
.jaUUmd {
  position: relative;
  top: 3px;
}
.cDaeNv {
  position: absolute;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  border: 0px;
}
.bBUxUY {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(0, 104, 239);
  background-color: rgb(0, 104, 239);
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  opacity: 1;
}
.jpZMXX {
  position: relative;
  bottom: -7px;
  border-bottom-width: 2px;
  z-index: 0;
}
.AoCyl {
  color: rgb(0, 104, 239);
  font-size: 12px;
}
@media screen and (min-width: 32em)
{.AoCyl {
    font-size: 12px;
}}
@media screen and (min-width: 40em)
{.AoCyl {
    font-size: 14px;
}}
.jTDlpr {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.euxmHg {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  opacity: 1;
}
.bgyled {
  margin-right: 30px;
}
.eTQiGA {
  color: rgb(79, 111, 143);
  font-size: 12px;
}
@media screen and (min-width: 32em)
{.eTQiGA {
    font-size: 12px;
}}
@media screen and (min-width: 40em)
{.eTQiGA {
    font-size: 14px;
}}
.iiUSEP {
  width: 25px;
  height: 25px;
  border-radius: 75px;
  border: 1px solid rgb(192, 202, 213);
  background-color: transparent;
  z-index: 1;
}
.fGTRVB {
  position: relative;
  top: 4px;
  left: 0.5px;
}
.lbsfXf {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin-left: 0px;
  margin-right: 0px;
}
.fwXifE {
  padding: 4px 32px 8px;
}
@media screen and (min-width: 40em)
{.fwXifE {
    padding-left: 88px;
    padding-right: 115px;
}}
@media screen and (min-width: 64em)
{.fwXifE {
    padding-left: 215px;
    padding-right: 243px;
}}
.fffrGg {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.dTyPIi {
  color: rgb(0, 104, 239);
  margin-left: -4px;
}
@media screen and (min-width: 32em)
{.dTyPIi {
    margin-left: -12px;
}}
@media screen and (min-width: 40em)
{.dTyPIi {
    margin-left: 28px;
}}
.cxkuqi {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ftlbro {
  color: rgb(0, 104, 239);
  margin-left: 28px;
}
.bvMvRt {
  color: rgb(79, 111, 143);
  margin-right: -22px;
}
.KSipl {
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
.klVZFh {
  border-radius: 16px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
  border-width: 1px;
}
.yOFdB {
  margin: 8px 16px 16px;
}
@media screen and (min-width: 48em)
{.yOFdB {
    margin-left: 0px;
    margin-right: 0px;
}}
.eWcoQd {
  color: rgb(0, 24, 51);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 16px;
}
.cHzsBf {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
}
.kXWwGH {
  display: flex;
  flex-wrap: wrap;
}
.ipZQDb {
  margin-top: 8px;
}
.dgkwrF {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
}
.gRgPQs {
  position: relative;
}
.hDSKSG {
  border-radius: 12px;
}
.lgdAME {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.fMPBrN {
  position: relative;
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(0, 24, 51);
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  margin: 0px;
  padding: 14px 12px;
  border-color: rgb(192, 202, 213);
  font-size: 16px;
  border-radius: 12px;
}
@media screen and (min-width: 40em)
{.fMPBrN {
    font-size: 14px;
}}
.emfZoy {
  height: 48px;
}
.jOxjSs {
  display: flex;
  flex-direction: column;
}
.dqZlIa {
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
}
.hIzNXN {
  position: absolute;
  right: 12px;
  top: 50%;
}
.jnykfy {
  transform: translateY(-50%);
}
.gGTlCk {
  display: block;
  max-width: 100%;
  width: 48px;
}
.kvJOXI {
  padding: 16px 16px 4px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
.cnzfrU {
  padding-bottom: 12px;
}
.enYEWO {
  background-color: rgb(244, 246, 248);
  color: rgb(0, 24, 51);
  border-radius: 12px;
  padding: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
  text-align: left;
}
@media screen and (min-width: 32em)
{.enYEWO {
    margin-left: 8px;
    margin-right: 8px;
}}
@media screen and (min-width: 40em)
{.enYEWO {
    margin-left: 8px;
    margin-right: 8px;
}}
@media screen and (min-width: 48em)
{.enYEWO {
    margin-left: 0px;
    margin-right: 0px;
}}
.gvfTfv {
  text-align: left;
  font-weight: 400;
  padding: 10px;
  line-height: 16px;
  font-size: 12px;
}
.kYOVZP {
  color: rgb(0, 24, 51);
  margin-top: 8px;
  font-size: 20px;
  font-weight: 700;
}
.ipzmek {
  margin-top: 16px;
}
.fCsXwM {
  display: flex;
  flex-direction: row;
}
.jUZlZF {
  width: 50%;
}
.ganGCn {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  margin-right: 4px;
}
.fPsAVt {
  border-radius: 12px;
}
.eFkDcU {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 24, 51);
  margin-left: 4px;
}
.cMtyuI {
  display: block;
  width: 100%;
  margin: 0px;
  z-index: 1;
  color: rgb(79, 111, 143);
  font-weight: 700;
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 0.2px;
}
.jAaJVn {
  margin-top: 4px;
}
.jDcnOJ {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
  padding: 2px;
  cursor: pointer;
  background-color: inherit;
  color: rgb(94, 104, 114);
}
.iHzcQK {
  color: rgb(0, 24, 51);
  font-weight: 500;
  margin-left: 4px;
  margin-top: 1px;
  font-size: 14px;
}
.kgkxcK {
  appearance: none;
  opacity: 0;
  position: absolute;
  z-index: 0;
}
.ligbEt {
  flex: 0 0 auto;
  line-height: 1;
  width: 24px;
}
.jDcnOJ svg {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 2px;
}
.jDcnOJ svg[data-name="checked"] {
  display: none;
}
.jDcnOJ svg {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 2px;
}
.jDcnOJ svg[data-name="indeterminate"] {
  display: none;
}
.jDcnOJ svg {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 2px;
}
.jDcnOJ > input:not([data-indeterminate="true"]) ~ svg[data-name="empty"] {
  color: rgb(79, 111, 143);
}
.jWRlYb {
  position: sticky;
  top: 0px;
  padding-top: 5px;
}
.oSEsZ {
  border-radius: 16px;
}
.hMraCr {
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
}
.hPiIOA > div {
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
}
@media screen and (min-width: 64em)
{.hPiIOA > div {
    border-width: 1px;
    border-radius: 16px;
}}
.HVbzn {
  padding: 16px 16px 8px;
}
.jQhrFw {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.hIsGgt {
  padding-bottom: 8px;
  margin-bottom: 0px;
}
.iNHyqo {
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.sUfqF {
  color: rgb(0, 102, 0);
  font-size: 14px;
}
.hWbHIU {
  display: inline-block;
}
.kdEqry {
  color: rgb(0, 102, 0);
  font-size: 14px;
}
.kWkErr {
  padding-left: 16px;
}
.jHufbi {
  margin-top: 4px;
  margin-bottom: 0px;
}
.gNuJFu {
  color: rgb(0, 24, 51);
  font-size: 14px;
}
.hWbHIU {
  display: inline-block;
}
.ipzWBx {
  color: rgb(0, 24, 51);
  font-size: 14px;
}
.dGKWer {
  margin-bottom: 8px;
}
.cBOEBA {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin: 0px;
  opacity: 1;
}
.fqEUxk {
  padding: 16px;
  margin-bottom: 0px;
}
.fQEYCI {
  color: rgb(0, 170, 0);
  font-size: 16px;
  font-weight: 700;
}
.fAKedv {
  color: rgb(0, 170, 0);
  font-size: 16px;
  font-weight: 700;
}
.gNuJFu {
  color: rgb(0, 24, 51);
  font-size: 14px;
}
.dxEKEb {
  margin-left: 4px;
}
.gepRhu {
  display: inline-block;
  outline: none;
  user-select: none;
}
.fWRYbD {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(0, 24, 51);
  color: rgb(255, 255, 255);
}
.kqrqSr {
  padding: 0px;
  background-color: transparent;
  color: inherit;
}
.gqYxoE {
  vertical-align: text-bottom;
}
.jXzHfI {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.kqrqSr > div {
  display: flex;
}
.ewVeEI {
  display: flex;
}
.buWeuk {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
}
.hmQMEb {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
}
.xtwhi {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: -8px;
  padding-bottom: 8px;
  margin-bottom: 0px;
}
.gQhiCX {
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 8px;
}
@media screen and (min-width: 64em)
{.gQhiCX {
    padding-left: 0px;
    padding-bottom: 0px;
}}
.hPiIOA > div {
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
}
@media screen and (min-width: 64em)
{.hPiIOA > div {
    border-width: 1px;
    border-radius: 16px;
}}
.euPQdY {
  color: rgb(79, 111, 143);
  padding-bottom: 4px;
  font-size: 12px;
}
.jYMkmA {
  margin-top: -16px;
  margin-bottom: 16px;
  margin-left: -16px;
  border-radius: 16px;
}
@media screen and (min-width: 64em)
{.jYMkmA {
    margin-top: -8px;
    margin-bottom: 8px;
    margin-left: 0px;
}}
.bffjmu {
  border: 1px solid rgb(192, 202, 213);
}
.dUQmGt {
  padding: 16px;
}
.bzumZI {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.ePgjVd {
  font-size: 12px;
}
.bAhxyC {
  padding-bottom: 8px;
}
.cDZpWC {
  padding-right: 4px;
}
.qyedh {
  color: rgb(0, 104, 239);
}
.kkPrOH {
  padding-bottom: 16px;
}
.bffiwg {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 8px;
}
.buWeuk {
  color: rgb(0, 24, 51);
  font-size: 16px;
  font-weight: 700;
}
.fQefJM {
  color: rgb(0, 24, 51);
  font-size: 16px;
}
.cIUJpZ {
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.ewVeEI {
  display: flex;
}
.fqslXb {
  border-radius: 16px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
.hfQmrY {
  margin-right: 0px;
}
.hjPUro {
  width: 42%;
  height: 84%;
  max-height: 62vw;
  min-height: 80px;
  min-width: 80px;
  overflow: hidden;
}
.gkkCmx {
  display: block;
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 16px;
}
.cVIExE {
  margin-left: 16px;
}
.yeTyp {
  min-width: 142px;
}
.hJyqwh {
  display: flex;
  flex-direction: column-reverse;
}
.OpuFr {
  font-weight: 700;
  font-size: 16px;
}
@media screen and (min-width: 48em)
{.OpuFr {
    font-size: 20px;
}}
.iXpLZJ {
  display: none;
}
@media screen and (min-width: 48em)
{.iXpLZJ {
    display: block;
}}
.iXpLZJ div:first-child {
  margin: 4px 0px;
}
.bmWfmj {
  color: rgb(79, 111, 143);
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 4px;
}
.iXpLZJ div:first-child {
  margin: 4px 0px;
}
.wBbuO {
  margin-top: 4px;
  margin-bottom: 4px;
}
.cWxNMU {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4;
  background-color: rgb(246, 128, 19);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 0.025em;
  border-radius: 8px;
  padding: 4px 8px;
}
.iXpLZJ div:first-child {
  margin: 4px 0px;
}
.gZlWox {
  font-weight: 700;
  font-size: 12px;
  margin-left: 8px;
  margin-right: 8px;
}
.kBqT {
  white-space: nowrap;
  text-transform: uppercase;
}
.loalHW {
  font-weight: 700;
  font-size: 12px;
}
.jkhOWQ {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 170, 0);
  width: 18px;
}
.fa-DiEf {
  color: rgb(0, 170, 0);
  font-size: 12px;
}
@media screen and (min-width: 64em) and (max-width: 90.99em)
{.innMlq {
    display: none;
}}
.fXKnj {
  margin-top: 16px;
  border-radius: 16px;
}
.cBNmrf {
  border-width: 0px;
  border-radius: 16px;
  border-color: rgb(192, 202, 213);
  border-style: solid;
}
.cbxzc {
  background-color: rgb(244, 246, 248);
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 40em)
{.cbxzc {
    flex-direction: row;
}}
@media screen and (min-width: 40em)
{.fQRFI {
    padding-left: 32px;
    padding-right: 32px;
    width: 66.6667%;
}}
.knA-dlQ {
  display: flex;
  justify-content: space-evenly;
  -webkit-box-align: center;
  align-items: center;
}
@media screen and (min-width: 48em)
{.knA-dlQ {
    -webkit-box-pack: start;
    justify-content: flex-start;
}}
.gPDLyS {
  margin: 16px;
}
.jvgMwg {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.TyBnQ {
  color: rgb(79, 111, 143);
  font-size: 10px;
  font-weight: 700;
}
@media screen and (min-width: 40em)
{.TyBnQ {
    font-size: 12px;
}}
.hdtqIg {
  white-space: nowrap;
}
.cZKRgL {
  color: rgb(0, 24, 51);
  font-size: 12px;
  font-weight: 700;
}
@media screen and (min-width: 40em)
{.cZKRgL {
    font-size: 16px;
}}
.hdtqIg {
  white-space: nowrap;
}
.jUDPJL {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin-left: 0px;
  margin-right: 0px;
  opacity: 1;
}
.kHjPeQ {
  transform: rotate(90deg);
  width: 40px;
  background-color: rgb(192, 202, 213);
}
.ga-dSMk {
  visibility: hidden;
}
@media screen and (min-width: 40em)
{.dzSxmp {
    padding: 0.333333px;
}}
.bishko {
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-color: rgb(192, 202, 213);
  background-color: rgb(192, 202, 213);
  margin-left: 0px;
  margin-right: 0px;
  opacity: 1;
}
.cRQEIs {
  font-weight: 700;
  margin-top: 8px;
  font-size: 16px;
}
.cDnnkr {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
}
.ikGIRk {
  font-size: 12px;
  margin-left: 8px;
}
.khNAfa {
  color: rgb(0, 170, 0);
  font-size: 12px;
  margin-left: 8px;
}
.blvlXi {
  color: rgb(0, 24, 51);
  font-size: 12px;
  margin-left: 8px;
}
.dgIQti {
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}
@media screen and (min-width: 32em)
{.dgIQti {
    margin-bottom: 16px;
}}
.dOWJIt {
  color: rgb(0, 24, 51);
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
}
.cEtDdp {
  margin-right: 4px;
}
.hDXoSt {
  width: 50%;
}
.hDXoSt1 {
  width: 50%;
}
.gTeAQU {
  background-color: rgb(255, 255, 255);
}
.jRqWGA {
  position: absolute;
  top: 21px;
  left: 13px;
}
.eMwqna {
  pointer-events: none;
  width: 80px;
}
.lnLxis {
  display: block;
  width: 100%;
  margin: 0px 0px -20px 12px;
  z-index: 1;
  color: rgb(79, 111, 143);
  font-size: 10px;
  padding-top: 6px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.2px;
}
.dEczWB {
  appearance: none;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  display: block;
  font-family: inherit;
  width: 100%;
  padding: 14px 40px 14px 12px;
  border-color: rgb(192, 202, 213);
  border-radius: 12px;
  font-size: 16px;
  margin: 0px;
}
@media screen and (min-width: 40em)
{.dEczWB {
    font-size: 14px;
}}
.gJbZhj {
  height: 48px;
}
.ejafuv {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(79, 111, 143);
  width: 24px;
  margin-left: -32px;
}
.kYJYVV {
  pointer-events: none;
}
/* .gJbZhj + svg {
  visibility: hidden;
} */
option {
  background-color: #fff;
}
.dFawSu {
  margin-left: -40px;
}
.bkRYrQ {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 170, 0);
  width: 24px;
  margin-left: 8px;
  margin-right: 8px;
}
.jRqWGA {
  position: absolute;
  top: 21px;
  left: 13px;
}
.eMwqna {
  pointer-events: none;
  width: 80px;
}
.iWoTZL {
  width: calc(100% - 200px);
}
.gnRuek {
  background-color: rgb(255, 255, 255);
  margin-left: 4px;
}
.erMnSt {
  position: absolute;
  right: 12px;
  top: 0px;
}
.jnykfy {
  transform: translateY(-50%);
}
.nation{
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 12px;
}
/* .nation .css-b62m3t-container {
  margin-left: 0px;
  width: 100%;
  height: 50px;
} */
.nation .css-13cymwt-control {
  height: 40px;
  padding-top: 7px;
  /* margin-top: 5px; */
}
.nation .css-13cymwt-control:focus{
  height: 40px;
  padding-top: 7px;
  margin-top: 5px;
}
.nation .css-13cymwt-control:active{
  height: 40px;
  padding-top: 7px;
  margin-top: 5px;
}
.titlel{
  margin-bottom: 8px;
}
.juJwPl {
  margin-top: 16px;
}
.gayMAW {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.kXpTRG {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(0, 104, 239);
  color: rgb(255, 255, 255);
}
.kXpTRG:hover {
  background-color: rgb(33 75 131);
  color: white !important;
  cursor: pointer;
}
.kXpTRG:active {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.5;
  cursor: pointer;
  border-width: 0px;
  border-style: solid;
  border-radius: 9999px;
  font-size: 14px;
  padding: 9.5px 18px;
  background-color: rgb(33 75 131);
  color: white !important;
}
.hRHOFX {
  margin: 16px auto 128px;
}
@media (min-width: 720px)
{.hRHOFX {
    margin: 8px auto 32px;
}}
.feTjmC {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media screen and (min-width: 48em)
{.feTjmC {
    -webkit-box-align: center;
    align-items: center;
}}
.hSCRIz {
  color: rgb(0, 170, 0);
  font-weight: 900;
  padding-right: 1px;
  font-size: 14px;
}
.hPtDTS {
  color: rgb(0, 170, 0);
  font-size: 24px;
  font-weight: 900;
  line-height: 22px;
}
.hPtDTS sub{
  color: rgb(0, 170, 0);
  font-size: 14px;
  font-weight: 700;
  margin-left: -7px;  
}
@media screen and (min-width: 40em)
{.hPtDTS {
    line-height: 26px;
}}
@media screen and (min-width: 48em)
{.hPtDTS {
    font-size: 32px;
    line-height: 34px;
}
}
.ejKHmD {
  width: 100%;
  padding: 8px;
}
@media screen and (min-width: 32em)
{.ejKHmD {
    padding-left: 16px;
    padding-right: 16px;
}}
.XBPds {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
}
@media screen and (min-width: 32em)
{.XBPds {
    -webkit-box-pack: start;
    justify-content: flex-start;
}}
.dUrgsk {
  border-top: 1px solid rgb(192, 202, 213);
}
@media screen and (min-width: 32em)
{.dUrgsk {
    padding-left: 16px;
}}
.kXNzlE {
  flex: 0 0 auto;
  line-height: 1;
  color: rgb(0, 170, 0);
  margin-right: 4px;
  width: 16px;
}
.jSvzBt {
  margin-right: 16px;
  font-size: 12px;
}
.jOxjSs {
  display: flex;
  flex-direction: column;
}
.eaysMm {
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}
.gwDspt {
  position: relative;
}
.liqqjd {
  display: flex;
  justify-content: space-evenly;
}
.eqLvhQ {
  min-width: 64px;
}
.hjovME {
  background-color: rgb(192, 202, 213);
  color: rgb(0, 24, 51);
}
.kIfGFQ {
  position: absolute;
  top: 20px;
}
.bYjYLq {
  height: 2px;
  width: 100%;
}
.jseTxF {
  display: flex;
  justify-content: space-around;
}
.gwDspt {
  position: relative;
}
.hrHiKC {
  color: rgb(0, 24, 51);
  font-size: 12px;
  margin-bottom: 1px;
}
.cxmKWP {
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  visibility: hidden;
  white-space: nowrap;
}
@media screen and (min-width: 40em)
{.cxmKWP {
    visibility: visible;
}}
@media screen and (min-width: 48em)
{.cxmKWP {
    font-size: 12px;
    visibility: visible;
}}
.guGZNG {
  background-color: rgb(79, 111, 143);
  margin: auto;
}
.cRBFGQ {
  margin-top: 2px;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
@media screen and (min-width: 375px)
{.cRBFGQ {
    margin-top: 0px;
    height: 7px;
    width: 7px;
}}
.eELagv {
  color: rgb(0, 24, 51);
  font-size: 12px;
  margin-top: 4px;
}
.ctgUaj {
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  visibility: hidden;
  white-space: nowrap;
}
@media screen and (min-width: 375px)
{.ctgUaj {
    visibility: visible;
}}
@media screen and (min-width: 48em)
{.ctgUaj {
    font-size: 12px;
    visibility: visible;
}}
.iflfBm {
  color: rgb(0, 24, 51);
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: right;
}
@media screen and (min-width: 32em)
{.iflfBm {
    font-size: 16px;
}}
.cAPVFj {
  color: rgb(0, 24, 51);
  font-weight: 700;
  font-size: 14px;
}
@media screen and (min-width: 32em)
{.cAPVFj {
    font-size: 16px;
}}
.kKhRMG {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: 16px;
  padding: 0px 4px;
  border-radius: 10px;
  height: 16px;
  font-size: 12px;
  white-space: nowrap;
  background-color: rgb(254, 242, 231);
  margin-bottom: 10px;
}
.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
}
.elementor-element {
  --widgets-spacing: 20px 20px;
}
@media (min-width: 391px)
{.elementor-column.elementor-col-16, .elementor-column[data-col="16"] {
    width: 16.666%;
}}
@media (min-width: 391px)
{.elementor-15091 .elementor-element.elementor-element-2bd3c5c {
    width: 23.722%;
}}
.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}
.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}
.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
  padding: 10px;
}
.elementor-15091 .elementor-element.elementor-element-2bd3c5c.elementor-column > .elementor-widget-wrap {
  justify-content: flex-end;
}
.elementor-15091 .elementor-element.elementor-element-2bd3c5c.elementor-column.elementor-element[data-element_type="column"] > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}
.elementor-widget:not(:last-child) {
  margin-block-end: 20px;
}
.elementor-15091 .elementor-element.elementor-element-766a9e0 {
  text-align: left;
}
.elementor-element .elementor-widget-container {
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration, .4s);
}
.elementor-widget-heading .elementor-heading-title {
  margin: 0;
}
.elementor-widget-heading h4.elementor-heading-title {
  line-height: 1.2em;
}
.elementor-15091 .elementor-element.elementor-element-766a9e0 .elementor-heading-title {
  color: #FFFFFF;
  font-family: "DM Sans", Sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.elementor-widget-image {
  text-align: center;
}
.elementor img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
}
#pcln-global-header .header-redesign .nav-section .highlight-current-page-link, #pcln-global-header .header-redesign .global-header-nav-product-item .highlight-current-page-link, #pcln-global-header .header-redesign .global-header-nav-product-link .highlight-current-page-link {
  color: #0068ef!important;
}
.right-sidebar {
  animation: slideIn .3s ease-in-out forwards;
  background-color: #fff;
  box-shadow: -2px 0 5px 0 #0000001a;
  height: 95%;
  left: -50%;
  overflow-y: scroll;
  padding: 20px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  z-index: 999;
}
.flight-detail-sidebar{
  flex: 1 1 0px;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(216, 220, 222);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    margin-right: 16px;
    font-weight: 400;
    margin-top: 1em;
    font-size: 12px;
    line-height: 16px;
}

.sidebar-open .right-sidebar {
  left: 50%;
  animation: slideOut 0.3s ease-in-out forwards; /* Slide out animation */
}

.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 998; /* Below the sidebar but above the main content */
  
  display: none; /* Initially hidden */
}
.app-container.sidebar-open2 .overlay2 {
  display: block; /* Show overlay when sidebar is open */
}
.close-button {
  background: none;
  border: none;
  font-size: 1.3em;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #333;
}
@keyframes slideIn {
  from {
    left: -50%;
  }
  to {
    left: 50%;
  }
}

@keyframes slideOut {
  from {
    left: 50%;
  }
  to {
    left: -50%;
  }
}
.container4 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: .3em;
}
.container4 {
  background: #0068ef;
  color: #fff;
}
.flight-checkout-logo {
    height: 6em;
    width: auto;
}
.flight-checkout-logo .p-card-title {
  height: 100%;
}